import CoreServiceRambla from './CoreServiceRambla';

class LooksService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  fetchRetailerLooks() {
    return this.api.get('/v1/retailer-looks');
  }

  fetchAdminLooks() {
    return this.api.get('/v1/admin-looks');
  }

  fetchLook(uid) {
    return this.api.get(`/v1/retailer-looks/${uid}`);
  }

  fetchBrandLook(uid) {
    return this.api.get(`v1/admin-looks/${uid}`);
  }

  fetchLookTemplates() {
    return this.api.get('/v1/admin-looks/list-templates');
  }

  createBrandLook() {
    return this.api.get('/v1/admin-looks/create-empty-brand-look');
  }

  createLookDraft() {
    return this.api.get('/v1/retailer-looks/create-empty-draft');
  }

  createLookFromTemplate({ uid }) {
    return this.api.get(`/v1/admin-looks/${uid}/use-template`);
  }

  deleteLook(uid) {
    return this.api.delete(`/v1/retailer-looks/${uid}`);
  }

  deleteAdminLook(uid) {
    return this.api.delete(`/v1/admin-looks/${uid}`);
  }

  updateLook(content) {
    const { uid, name, description, visibility, media_ids } = content;
    return this.api.patch(`/v1/retailer-looks/${uid}`, {
      name,
      description,
      visibility,
      media_ids,
    });
  }

  updateBrandLook(content) {
    const { uid, name, description, visibility, media_ids, duplication_policy } = content;

    return this.api.patch(`/v1/admin-looks/${uid}`, {
      name,
      description,
      visibility,
      media_ids,
      duplication_policy,
    });
  }

  // patchLook(uid, content) {
  //   return this.api.patch(`/v2/store/${uid}`, content);
  // }

  // changeLookStatus(lookId, data) {
  //   return this.api.put(`/v2/store/${lookId}/status`, data);
  // }

  // updateIsPrivate(lookId, is_private) {
  //   return this.api.post(`/v2/store/${lookId}/private`, { is_private });
  // }

  // fetchLookProducts(lookId) {
  //   return this.api.get(`/v2/store/${lookId}/products`);
  // }

  // deleteLook(lookId) {
  //   return this.api.delete(`/v2/store/${lookId}`);
  // }

  // fetchAvailableProducts(regionUid) {
  //   return this.api.get(`/v3/retailer_info/products?region=${regionUid}`);
  // }

  // addProductsToLook(data) {
  //   return this.api.post('/v2/products', data);
  // }

  // patchProduct(lookId, parent_product, data) {
  //   return this.api.patch(`/v2/products/${parent_product}`, {
  //     ...data,
  //     store_id: lookId,
  //     parent_product,
  //   });
  // }

  // deleteProduct(lookId, productId) {
  //   return this.api.delete(`/v2/store/${lookId}/products/${productId}`);
  // }

  // searchProducts({ regionUid, productName }) {
  //   return this.api.get(`/v3/retailer_info/products?name=${productName}&region=${regionUid}`);
  // }

  // getStats(lookId) {
  //   return this.api.get(`/v3/entry/social_views?entry_id=${lookId}`);
  // }

  // genereateStoreLinks(shortId, withToken = false, duration = 1) {
  //   return this.api.post(`/v2/store/${shortId}/generatelink`, {
  //     with_token: withToken,
  //     duration,
  //   });
  // }

  // removeCoverPhoto(cover_photo_id) {
  //   return this.api.delete(`/v3/store_images/${cover_photo_id}`);
  // }

  // fetchCoverPhotos(lookId) {
  //   return this.api.get(`/v3/store_images/single/${lookId}`);
  // }

  // addCoverPhotos(store_id, cover_photo_id, sort_order) {
  //   return this.api.post(`/v3/store_images`, { store_id, cover_photo_id, sort_order });
  // }
}

const instance = new LooksService('');

export default instance;
