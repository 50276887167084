import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userLoggingSelector, ramblaUserSelector } from 'redux/auth/selectors';
import { DASHBOARD } from 'constants/routes';
import { Helmet } from 'react-helmet';
import useQuery from 'hooks/useQuery';

export function LoginRoute({ component: Component, ...rest }) {
  const query = useQuery();
  const redirect = query.get('redirectUrl');
  const to = redirect || DASHBOARD;
  const user = useSelector(ramblaUserSelector);
  const isUserLogging = useSelector(userLoggingSelector);

  if (user) {
    return <Redirect to={to} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isUserLogging) {
          return null;
        }
        if (user?.email) {
          return (
            <Redirect
              to={{
                pathname: to,
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <>
            <Helmet>
              <title>Rambla - Admin Panel</title>
            </Helmet>
            <Component {...props} />
          </>
        );
      }}
    />
  );
}

LoginRoute.propTypes = {
  component: PropTypes.func,
  rest: PropTypes.any,
};
