import React, { useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { toastr } from 'utils';
import history from 'utils/history';
import { FormWrapper } from 'components/Modal/styles';
import { Wrapper, Modal, Form, Button, PopupContext } from 'components';
import {
  inviteRetailerRambla,
  updateRetailerRambla,
  deleteRetailerRambla,
} from 'redux/retailers/actions';
import { ramblaRegionsSelector } from 'redux/regions/selectors';
import { mapInitialValues } from '../../utils/retailerFormUtils';

const socials = ['facebook', 'instagram', 'pinterest', 'twitter', 'tiktok'];

const RetailerForm = ({ type = 'create', initialValues, FormGroup, wrapperType }) => {
  const { closePopup } = useContext(PopupContext);
  const { t } = useTranslation(['pages', 'app', 'toastr']);
  const regions = useSelector(ramblaRegionsSelector);
  const dispatch = useDispatch();

  const mappedInitialValues = useMemo(() => mapInitialValues(initialValues), [initialValues]);

  const deleteAction = () => {
    toastr.confirm(t('toastr:retailerDelete'), {
      onOk: async () => {
        await dispatch(deleteRetailerRambla(initialValues?.uid));
        history.goBack();
      },
      dispatch,
    });
  };

  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
      }}
      enableReinitialize
      initialValues={
        pick(mappedInitialValues, [
          'uid',
          'email',
          'region_uid',
          'name',
          'lastName',
          'about',
          'instagram_id',
          'twitter_id',
          'tiktok_id',
          'facebook_id',
          'pinterest_id',
        ]) || {
          uid: '',
          email: '',
          region_uid: '',
          name: '',
          lastName: '',
          about: '',
          instagram_id: '',
          twitter_id: '',
          tiktok_id: '',
          facebook_id: '',
          pinterest_id: '',
        }
      }
      onSubmit={({
        uid,
        region_uid,
        email,
        name,
        lastName,
        about,
        instagram_id,
        facebook_id,
        twitter_id,
        pinterest_id,
        tiktok_id,
      }) =>
        type === 'update'
          ? dispatch(
              updateRetailerRambla({
                uid,
                email,
                region_uid,
                name,
                lastName,
                about,
                instagram_id,
                pinterest_id,
                facebook_id,
                tiktok_id,
                twitter_id,
              }),
            )
          : dispatch(inviteRetailerRambla({ email, region_uid }))
      }
    >
      <FormWrapper
        style={{
          minWidth: type === 'create' ? 800 : 400,
          maxWidth: type === 'create' ? 1000 : 600,
        }}
        type={wrapperType === 'modal' ? 'create' : type}
      >
        <Row className="d-flex justify-content-center">
          <Col md={12}>
            <FormGroup required label={t('pages:retailers.email')}>
              <Form.Input type="text" name="email" disabled={type === 'update'} />
            </FormGroup>
            {type === 'update' && (
              <>
                <FormGroup required label={t('pages:retailers.publisherId')}>
                  <Form.Input type="text" name="uid" />
                </FormGroup>
                <FormGroup required label={t('pages:retailers.name')}>
                  <Form.Input type="text" name="name" />
                </FormGroup>
                <FormGroup required label={t('pages:retailers.lastName')}>
                  <Form.Input type="text" name="lastName" />
                </FormGroup>
                <Form.Group>
                  <Form.Label>{t('pages:retailers.about')}</Form.Label>
                  <Form.Input as="textarea" name="about" style={{ maxHeight: '120px' }} />
                </Form.Group>
                {socials.map(social => (
                  <FormGroup label={social} key={social}>
                    <Form.Input type="text" name={social} />
                  </FormGroup>
                ))}
              </>
            )}
            <FormGroup label={t('pages:retailers.region')}>
              <Form.MultiSelect
                options={regions.data.map(region => ({
                  value: region.uid,
                  label: region.name,
                }))}
                name="region_uid"
                isMulti={false}
              />
            </FormGroup>
          </Col>
        </Row>
      </FormWrapper>
      <Modal.Footer>
        {() => (
          <Wrapper d-flex justify-content-end>
            {type !== 'create' && (
              <Button onClick={deleteAction} variant="danger" className="mr-3">
                {t('app:delete')}
              </Button>
            )}
            <div>
              <Button onClick={closePopup} variant="white" className="mr-3">
                {t('app:cancel')}
              </Button>
              <Form.Submit>
                {type === 'create'
                  ? t('components:inviteRetailer.inviteNow').toUpperCase()
                  : t('app:save').toUpperCase()}
              </Form.Submit>
            </div>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

RetailerForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  wrapperType: PropTypes.string,
};

export default RetailerForm;
