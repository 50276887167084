import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';

import useEntityForm from 'hooks/useEntityForm';
import { FormWrapper } from 'components/Modal/styles';
import { brandSelector } from 'redux/brand/selectors';
import { ramblaProductCategoriesSelector } from 'redux/products/selectors';
import { Wrapper, Modal, Form, Button } from 'components';
import { createParentProduct, updateParentProduct } from 'redux/products/actions';
import { mapInitialValues } from '../../utils/parentProductFormUtils';

const ParentProductForm = ({ type = 'create', initialValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { validationSchema } = useEntityForm(type, 'parent_product');
  const brand = useSelector(brandSelector);
  const categories = useSelector(ramblaProductCategoriesSelector);

  const mappedInitialValues = useMemo(() => mapInitialValues(initialValues), [initialValues]);

  return (
    <Form
      initialValues={
        pick(mappedInitialValues, ['uid', 'name', 'description', 'details', 'category_uid']) || {
          name: '',
          description: '',
          details: '',
          brand_uid: brand.data?.uid,
          category_uid: '',
        }
      }
      onSubmit={({ uid, name, description, details, category_uid }) =>
        type === 'update'
          ? dispatch(updateParentProduct(uid, name, description, details, category_uid))
          : dispatch(createParentProduct(name, description, details, brand.data?.uid, category_uid))
      }
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <Form.GroupVertical required label={t('createProduct.name')}>
          <Form.Input name="name" type="text" />
        </Form.GroupVertical>
        <Form.GroupVertical required label={t('createProduct.description')}>
          <Form.Input as="textarea" name="description" />
        </Form.GroupVertical>
        <Form.GroupVertical required label={t('createProduct.details')}>
          <Form.Input as="textarea" name="details" />
        </Form.GroupVertical>
        <Form.GroupVertical label={t('createProduct.category')}>
          <Form.MultiSelect
            options={categories.data.map(category => ({
              value: category.uid,
              label: category.name,
            }))}
            name="category_uid"
            isMulti={false}
          />
        </Form.GroupVertical>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

ParentProductForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
};

export default ParentProductForm;
