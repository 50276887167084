import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import Flatpickr from 'react-flatpickr';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { isEmpty, uniqBy, throttle, capitalize } from 'lodash';
import moment from 'moment';
import { toastr } from 'utils';
import { Form as BootstrapForm, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { Formik, Form as FormikForm, useField as formikUseField, useFormikContext } from 'formik';
import {
  ColorPicker,
  SectionToggle,
  ButtonWithIcon,
  ButtonWithArrows,
  ToggleButton,
} from 'components';

import 'react-image-crop/dist/ReactCrop.css';

import withDefaultProps from 'helpers/withDefaultProps';
import Visibility from '@material-ui/icons/Visibility';
import { VisibilityOff } from '@material-ui/icons';
import { BiInfoCircle } from 'react-icons/bi';

import EntityService from 'api/services/EntityService';
import { Tooltip } from '@material-ui/core';
import {
  Dropzone,
  ErrorFeedback,
  FilePreviewThumbnail,
  PreviewThumbnailWrapper,
  InputDescription,
  Checkbox as StyledCheckbox,
  Input as StyledInput,
  RadioButton as StyledRadioButton,
} from './styles';

const animatedComponents = makeAnimated();

const FormContext = React.createContext();

let timeout;
const cleanupFunction = () => clearTimeout(timeout);
const HANDLE_SUBMIT_TIMEOUT = 600;

function useField(props) {
  const fieldProps = formikUseField(props);
  const form = useFormikContext();
  fieldProps[1].touched = fieldProps[1].touched || form.submitCount > 0;
  return fieldProps;
}

const Form = ({ children, notifyOnFormErrors, style, ...restProps }) => (
  <FormContext.Provider value={{ notifyOnFormErrors }}>
    <Formik validateOnMount enableReinitialize {...restProps}>
      {values => {
        if (typeof children === 'function') {
          return <FormikForm style={style}>{children(values)}</FormikForm>;
        }

        return <FormikForm style={style}>{children}</FormikForm>;
      }}
    </Formik>
  </FormContext.Provider>
);

Form.Group = ({ children, ...restProps }) => (
  <BootstrapForm.Group {...restProps}>{children}</BootstrapForm.Group>
);

Form.Label = ({ children, ...restProps }) => (
  <BootstrapForm.Label {...restProps}>{children}</BootstrapForm.Label>
);

Form.CreatableSelect = function CreatableMultiSelect({ ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const createOption = label => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const [inputValue, setInputValue] = React.useState('');

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue([...field.value, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };
  const selectCustomStyle = isInvalid ? { border: '1px solid red' } : {};

  return (
    <>
      <CreatableSelect
        {...field}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={newValue => {
          setValue(newValue);
        }}
        onInputChange={newValue => {
          setInputValue(newValue);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        styles={{
          control: provided => ({ ...provided, ...selectCustomStyle }),
        }}
      />

      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.MultiSelect = function MultiSelect({
  options,
  isMulti = true,
  children,
  disabled = false,
  withSelectAll = false,
  ...props
}) {
  const [field, meta, { setValue }] = useField(props);

  const isInvalid = meta.touched && meta.error;

  const onChange = selectedOptions => {
    if (isMulti) {
      if (selectedOptions?.find(option => option.value === 'all')) {
        setValue(options.map(option => option.value));
        return;
      }
      setValue(selectedOptions?.map(option => option.value) || []);
    } else {
      setValue(selectedOptions.value);
    }
  };

  const getValue = () =>
    isMulti
      ? options.filter(option => field.value?.indexOf(option.value) >= 0)
      : options.filter(option => field.value === option.value);

  const selectCustomStyle = isInvalid ? { border: '1px solid red' } : {};
  const disabledStyle = disabled ? { border: 'none', backgroundColor: 'transparent' } : {};

  const value = getValue();

  if (!value.length && disabled) {
    return null;
  }

  return (
    <>
      <Select
        {...field}
        closeMenuOnSelect={false}
        components={animatedComponents}
        options={[withSelectAll && { label: 'Select All', value: 'all' }, ...options]}
        isMulti={isMulti}
        isDisabled={disabled}
        onChange={onChange}
        value={value}
        isOpen
        styles={{
          control: provided => ({
            ...provided,
            ...selectCustomStyle,
            ...disabledStyle,
            height: '44.39px',
          }),
          dropdownIndicator: provided => (disabled ? { display: 'none' } : provided),
          indicatorSeparator: provided => (disabled ? { display: 'none' } : provided),
          multiValueRemove: provided => (disabled ? { display: 'none' } : provided),
          option: (styles, { data }) => {
            if (withSelectAll && data.value === 'all') {
              return { ...styles, fontWeight: 'bold' };
            }
            return styles;
          },
        }}
        className={`text-left ${isInvalid && 'is-invalid'}  ${withSelectAll && '.select-all'}`}
        {...props}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

let requestId = 0;

Form.MultiSelectSalon = function MultiSelectSalon({ disabled = false, ...props }) {
  const [field, meta, { setValue }] = useField('location');
  const selectedIds = field.value?.map(({ id }) => id) || [];
  const isInvalid = meta.touched && meta.error;

  const [locations, setLocations] = React.useState(field.value || []);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const onChange = selectedOptions => {
    const newValue =
      selectedOptions?.map(option => locations.find(({ id }) => option.value === id)) || [];
    setValue(newValue);
  };

  const locationsSelect = locations.map(({ label, id }) => ({
    label,
    value: id,
  }));
  const selectedLocations = locationsSelect.filter(({ value }) => selectedIds.indexOf(value) >= 0);
  const onInputChange = search => {
    if (!search) {
      return;
    }
    requestId += 1;

    setMenuIsOpen(true);
    // TODO: error handling
    const fetchLocations = async fetchRequestId => {
      const response = await EntityService.fetch('location', undefined, { search });
      const locationsPage = response?.results || [];
      const locationsOptions = locationsPage.map(
        ({ name: locationName, sap_id, uid, id, owners }) => ({
          label: `${locationName} - ${sap_id}`,
          uid,
          id,
          owners,
        }),
      );
      const previousLocations = locations.filter(({ id }) => selectedIds.indexOf(id) >= 0);
      const newLocations = uniqBy(previousLocations.concat(locationsOptions), ({ id }) => id);
      if (fetchRequestId !== requestId) {
        // new fetch has been started
        return;
      }
      setLocations(newLocations);
    };
    fetchLocations(requestId);
  };
  const onInputChangeThrottled = throttle(onInputChange, 300);

  const selectCustomStyle = isInvalid ? { border: '1px solid red' } : {};
  const disabledStyle = disabled ? { border: 'none', backgroundColor: 'transparent' } : {};

  const onFocus = () => {
    if (locationsSelect.length) {
      setMenuIsOpen(true);
    }
  };

  return (
    <>
      <Select
        {...field}
        filterOption={() => true} // this makes all the difference - filtering occurs on server side
        closeMenuOnSelect={false}
        components={animatedComponents}
        placeholder="type to search"
        options={locationsSelect}
        isMulti
        isDisabled={disabled}
        onChange={onChange}
        menuIsOpen={menuIsOpen}
        onFocus={onFocus}
        onBlur={() => setMenuIsOpen(false)}
        onInputChange={onInputChangeThrottled}
        value={selectedLocations}
        styles={{
          control: provided => ({ ...provided, ...selectCustomStyle, ...disabledStyle }),
          indicatorsContainer: provided => ({
            ...provided,
            display: locationsSelect.length ? 'flex' : 'none',
          }),
          indicatorSeparator: provided => (disabled ? { display: 'none' } : provided),
          multiValueRemove: provided => (disabled ? { display: 'none' } : provided),
        }}
        className={`text-left ${isInvalid && 'is-invalid'}`}
        {...props}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.DatePicker = function DatePicker({ options = {}, ...props }) {
  const [field, meta, { setValue }] = useField(props);

  const isInvalid = meta.touched && meta.error;

  const onChange = date => {
    setValue(moment(date[0]).format('YYYY-MM-DD'));
  };

  return (
    <>
      <Flatpickr
        {...field}
        {...props}
        placeholder="YYYY-MM-DD"
        options={{
          dateFormat: 'Y-m-d',
          ...options,
        }}
        className={`form-control ${isInvalid && 'is-invalid'}`}
        value={field.value}
        onChange={onChange}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.DateRangePicker = function DateRangePicker({ relativePeriods, ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const ref = useRef();
  const { t } = useTranslation(['app']);

  const dateFormat = 'YYYY-MM-DD';

  const startDate = useRef(
    moment()
      .subtract(relativePeriods[0].durationInDays, 'days')
      .format(dateFormat),
  );
  const endDate = useRef(moment().format(dateFormat));

  const isInvalid = meta.touched && meta.error;

  const onChange = ([date], type) => {
    let sd;
    let ed;

    if (type === 'start') {
      sd = moment(date);
      ed = moment(endDate.current);
    } else {
      sd = moment(startDate.current);
      ed = moment(date);
    }

    const diff = ed.diff(sd, 'days');

    const period = relativePeriods.find(p => p.durationInDays === diff);

    if (period && moment().diff(ed, 'days') === 0) {
      ref.current.value = period.durationInDays;
    } else {
      ref.current.value = 0;
    }

    startDate.current = sd.format(dateFormat);
    endDate.current = ed.format(dateFormat);
    setValue({ endDate: ed.format(dateFormat), startDate: sd.format(dateFormat) });
  };

  useEffect(() => {
    setValue({ startDate: startDate.current, endDate: endDate.current });
  }, []); // set default period based on passed relativePeriods

  const handlePeriodChange = e => {
    const ed = moment();

    const sd = moment().subtract(e.target.value, 'days');
    setValue({ startDate: sd.format(dateFormat), endDate: ed.format(dateFormat) });
  };

  return (
    <div className="d-flex flex-column">
      <BootstrapForm.Control
        className="custom-select"
        as="select"
        onChange={handlePeriodChange}
        isInvalid={isInvalid}
        ref={ref}
      >
        {relativePeriods.map(({ durationInDays, label }) => (
          <option key={durationInDays} value={durationInDays}>
            {label}
          </option>
        ))}
        <option value={0} hidden>
          {t('app:customPeriod')}
        </option>
      </BootstrapForm.Control>
      <Flatpickr
        placeholder="YYYY-MM-DD"
        options={{
          dateFormat: 'Y-m-d',
          maxDate: moment().format(dateFormat),
        }}
        className={`form-control ${isInvalid && 'is-invalid'} mt-2`}
        value={field.value.startDate}
        onChange={date => onChange(date, 'start')}
      />
      <Flatpickr
        placeholder="YYYY-MM-DD"
        options={{
          dateFormat: 'Y-m-d',
          maxDate: moment().format(dateFormat),
        }}
        className={`form-control ${isInvalid && 'is-invalid'} mt-2`}
        value={field.value.endDate}
        onChange={date => onChange(date, 'end')}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </div>
  );
};

Form.ColorPicker = function PickColorInput({ ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const { t } = useTranslation(['app']);

  const [isPickerComponentVisible, setVisibility] = useState(false);

  const isInvalid = meta.touched && meta.error;

  const onChange = color => {
    setValue(color);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex' }}>
        <Form.Input
          type="text"
          {...props}
          placeholder={t('app:pickColor')}
          onFocus={() => setVisibility(true)}
        />
        <button
          type="button"
          onClick={() => setVisibility(true)}
          style={{
            backgroundColor: field.value,
            border: '1px solid  #d2ddec',
            width: 50,
            borderRadius: '0.375rem',
            marginLeft: 3,
          }}
        />
      </div>

      {isPickerComponentVisible && (
        <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
          <ColorPicker onChange={onChange} onHide={() => setVisibility(false)} />
        </div>
      )}
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </div>
  );
};

Form.Input = function Input({
  append = '',
  prepend = '',
  description = '',
  children,
  mask = false,
  ...props
}) {
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error;

  if (append || prepend) {
    return (
      <>
        <InputGroup className="w-100">
          <StyledInput mask={mask ? 1 : 0}>
            {prepend && (
              <InputGroup.Text className="rounded-0 rounded-left border-right-0">
                {prepend}
              </InputGroup.Text>
            )}
            <BootstrapForm.Control
              sm={10}
              {...field}
              {...props}
              isInvalid={isInvalid}
              className={`rounded-0 ${
                prepend && append ? '' : prepend ? 'rounded-right' : 'rounded-left'
              }`}
            />
            {append && (
              <InputGroup.Text className="rounded-0 rounded-right border-left-0">
                {append}
              </InputGroup.Text>
            )}
          </StyledInput>
          {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
        </InputGroup>
      </>
    );
  }

  return (
    <>
      <StyledInput mask={mask ? 1 : 0}>
        <BootstrapForm.Control sm={10} {...field} {...props} isInvalid={isInvalid} />
      </StyledInput>
      {!!description.length && <InputDescription>{description}</InputDescription>}

      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.Password = function Password({ children, ...props }) {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = React.useState(false);
  const isInvalid = meta.touched && meta.error;

  return (
    <>
      <InputGroup>
        <BootstrapForm.Control
          sm={10}
          type={showPassword ? 'text' : 'password'}
          {...field}
          {...props}
          isInvalid={isInvalid}
        />
        <InputGroup.Append onClick={() => setShowPassword(prev => !prev)}>
          <InputGroup.Text className="h-100 rounded-0 rounded-right border-left-0">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputGroup.Text>
        </InputGroup.Append>
        {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
      </InputGroup>
    </>
  );
};

Form.Select = function SelectInput({ options, disabled = false, ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const onChange = selectedOption => {
    setValue(selectedOption ? selectedOption.value : '');
  };

  const selectCustomStyle = isInvalid ? { border: '1px solid red' } : {};
  const disabledStyle = disabled ? { border: 'none', backgroundColor: 'transparent' } : {};

  const value = options.find(option => option.value === field.value);

  return (
    <>
      <Select
        {...field}
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        styles={{
          control: provided => ({
            ...provided,
            ...selectCustomStyle,
            ...disabledStyle,
            height: '44.39px',
          }),
          dropdownIndicator: provided => (disabled ? { display: 'none' } : provided),
          indicatorSeparator: provided => (disabled ? { display: 'none' } : provided),
          option: styles => styles,
        }}
        className={`text-left ${isInvalid && 'is-invalid'}`}
        {...props}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.FileUpload = function FileUpload({ maxFiles = 6, accept = 'image/*', ...props }) {
  const { t } = useTranslation('app');
  const [field, meta, { setValue }] = useField(props);
  const isInvalid = meta.touched && meta.error;
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: async acceptedFiles => {
      const currentFiles = Array.isArray(field.value)
        ? field.value
        : field.value
        ? [field.value]
        : [];
      if (currentFiles.length === maxFiles) {
        setValue([...currentFiles.slice(1), ...acceptedFiles]);
      } else {
        setValue([...currentFiles, ...acceptedFiles]);
      }
    },
  });

  const handleFileDelete = file => setValue(field.value.filter(item => item.name !== file.name));

  return (
    <>
      <Dropzone {...getRootProps({ refKey: 'innerRef' })}>
        <input {...getInputProps()} disabled={props.disabled} />
        <p className="text-muted text-center">
          {maxFiles === 1 ? t('dropzonePlaceholderSingle') : t('dropzonePlaceholder')}
        </p>
      </Dropzone>
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
      <PreviewThumbnailWrapper>
        {field.value?.map(file => (
          <FilePreviewThumbnail>
            {!props.disabled && (
              <button
                type="button"
                className="btn-white rounded-circle"
                disabled={props.disabled}
                onClick={() => handleFileDelete(file)}
              >
                <i className="fe fe-x" />
              </button>
            )}
            <div className="flex-1">
              <h4>{file?.name}</h4>
            </div>
          </FilePreviewThumbnail>
        ))}
      </PreviewThumbnailWrapper>
    </>
  );
};

Form.Checkbox = function Checkbox({ children, ...props }) {
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error;

  return (
    <>
      <StyledCheckbox
        type="checkbox"
        checked={field.value}
        {...field}
        {...props}
        isInvalid={isInvalid}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </>
  );
};

Form.RadioButton = function RadioButton({ children, ...props }) {
  const [field, meta] = useField(props);
  const { label } = props;
  const { handleMouseOver, handleMouseOut, value, name } = props;
  const { fieldValue } = field;
  const { values } = useFormikContext();
  const isInvalid = meta.touched && meta.error;

  return (
    <div
      className="d-flex"
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
    >
      <StyledRadioButton
        id={`option-${fieldValue}`}
        label={label}
        type="radio"
        {...field}
        {...props}
        isInvalid={isInvalid}
        checked={values[name] === value}
      />
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </div>
  );
};

Form.MultiCheckbox = function MultiCheckbox({ options, ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const { t } = useTranslation(['app']);
  const isInvalid = meta.touched && meta.error;

  const all = 'all';

  useEffect(() => {
    if (options.length > 1 && options.length === field.value.length)
      setValue([...field.value, all]);
  }, []);

  const handleValueSelection = value => {
    if (value === all) {
      if (options.every(opt => field.value.includes(opt.value))) {
        setValue([]);
      } else {
        setValue([...options.map(opt => opt.value), all]);
      }
    } else if (field.value.includes(value)) {
      setValue(field.value.filter(v => v !== value && v !== all));
    } else if (field.value.length === options.length - 1 && !field.value.includes(all)) {
      setValue([...field.value, value, all]);
    } else {
      setValue([...field.value, value]);
    }
  };

  return (
    <>
      {options.length > 1 && (
        <div className="d-flex">
          <StyledCheckbox
            type="checkbox"
            checked={field.value.includes('all')}
            onChange={() => handleValueSelection('all')}
          />
          <div>{t('app:selectAll')}</div>
        </div>
      )}
      {options.map(({ value, label }) => (
        <div className="d-flex">
          <StyledCheckbox
            type="checkbox"
            checked={field.value.includes(value)}
            onChange={() => handleValueSelection(value)}
            {...props}
            isInvalid={isInvalid}
          />
          <div>{label}</div>
        </div>
      ))}
      {isInvalid && <ErrorFeedback>{meta.error}</ErrorFeedback>}
    </>
  );
};

Form.DependentField = function DependentField({
  dependencyName,
  calculateOptions,
  Component,
  initialOption = undefined,
  ...props
}) {
  const [{ value }, { touched }] = useField(dependencyName);
  const filteredValues = Array.isArray(value) ? value.filter(v => v !== 'all') : value;
  const [options, setOptions] = useState(initialOption || calculateOptions(filteredValues));

  useEffect(() => {
    setOptions(calculateOptions(filteredValues));
  }, [value, touched]);

  return <Component options={options} {...props} />;
};

Form.Toggle = function Checkbox({ ...props }) {
  const [field, meta] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const handleOnChange = e => {
    field.onChange(e);
    props?.onChange(e);
  };

  return (
    <ToggleButton
      checked={field.value}
      {...field}
      {...props}
      onChange={handleOnChange}
      isInvalid={isInvalid}
    />
  );
};

Form.DictionaryField = function DictionaryField({ children, withToggle, ...props }) {
  const [field, meta, { setValue }] = useField(props);
  const [numberOfInputs, setNumberOfInputs] = useState(field.value.length);
  const { t } = useTranslation(['app']);
  const isInvalid = meta.touched && meta.error;

  return (
    <FormikForm>
      <BootstrapForm.Control
        key={`${props.name}[0]`}
        sm={10}
        {...field}
        {...props}
        name={`${props.name}[0]`}
        value={field.value[0]}
        isInvalid={isInvalid}
      />
      {numberOfInputs > 1 &&
        (withToggle ? (
          <SectionToggle
            trigger={isOpen => (
              <Button variant="link" className="w-100 d-flex justify-content-center pb-0">
                <p className="text-small m-0">{isOpen ? t('showLess') : t('showMore')}</p>
                <i className={`fe fe-chevron-${isOpen ? 'up' : 'down'}`} />
              </Button>
            )}
            defaultOpen
          >
            {Array.from(Array(numberOfInputs - 1).keys()).map((_, i) => (
              <BootstrapForm.Control
                key={`${props.name}[${i + 1}]`}
                sm={10}
                {...field}
                {...props}
                name={`${props.name}[${i + 1}]`}
                value={field.value[i + 1]}
                isInvalid={isInvalid}
                className="mb-3"
              />
            ))}
          </SectionToggle>
        ) : (
          Array.from(Array(numberOfInputs - 1).keys()).map((_, i) => (
            <BootstrapForm.Control
              key={`${props.name}[${i + 1}]`}
              sm={10}
              {...field}
              {...props}
              name={`${props.name}[${i + 1}]`}
              value={field.value[i + 1]}
              isInvalid={isInvalid}
              className="mt-3"
            />
          ))
        ))}
      {!props.disabled && (
        <Row>
          <Col>
            {numberOfInputs > 1 && (
              <button
                type="button"
                onClick={() => {
                  if (numberOfInputs === field.value.length) {
                    setValue(field.value.slice(0, -1));
                  }
                  setNumberOfInputs(numberOfInputs - 1);
                }}
                className="btn btn-link p-0 btn-sm"
              >
                Remove last
              </button>
            )}
          </Col>
          <Col className="text-right">
            <button
              type="button"
              onClick={() => setNumberOfInputs(numberOfInputs + 1)}
              className="btn btn-link p-0 btn-sm"
            >
              Add more
            </button>
          </Col>
        </Row>
      )}
      {isInvalid ? <ErrorFeedback>{meta.error}</ErrorFeedback> : null}
    </FormikForm>
  );
};

export const getLabel = (label, tooltipText, required) => {
  const capitalizedLabel = capitalize(label);

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement="right">
        <span className="position-relative">
          <Form.Label>
            {capitalizedLabel}
            {required && <span style={{ color: 'red' }}>*</span>}
          </Form.Label>
          <BiInfoCircle className="ml-1 mb-1" size={15} />
        </span>
      </Tooltip>
    );
  }
  return (
    <Form.Label className="d-flex flex-col">
      {capitalizedLabel}
      {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
  );
};

Form.GroupVertical = ({ label, children, required, tooltipText, ...rest }) => (
  <Form.Group {...rest}>
    <Row className="align-items-center">
      <Col className="text-right d-flex align-items-center" sm="4">
        {getLabel(label, tooltipText, required)}
      </Col>
      <Col className="text-right" sm="8">
        {children}
      </Col>
    </Row>
  </Form.Group>
);

Form.GroupHorizontal = ({ label, children, required, style, tooltipText, ...rest }) => (
  <Form.Group style={style} {...rest}>
    {getLabel(label, tooltipText, required)}
    {children}
  </Form.Group>
);

Form.Submit = function Submit({
  children,
  animated,
  icon,
  onClick,
  areFormDependenciesReady = true,
  withOutSubmitAction = false,
  withArrows,
  ...restProps
}) {
  const { handleSubmit, isValid, touched, validateForm } = useFormikContext();

  const [clicked, setClicked] = React.useState(false);
  const { notifyOnFormErrors } = React.useContext(FormContext);
  const { t } = useTranslation(['toastr']);

  const handleOnClick = async () => {
    setClicked(true);
    if (notifyOnFormErrors) {
      const errors = await validateForm();
      if (!isEmpty(errors)) {
        setClicked(false);
        toastr.error(t('toastr:errorsInForm'), '', {
          component: (
            <>
              {Object.keys(errors).map(item => (
                <p className="text-small m-0">
                  <span className="text-danger">{item.toUpperCase()}</span> {errors[item]}
                </p>
              ))}
            </>
          ),
        });
      }
    }

    if (onClick) {
      setTimeout(() => {
        if (isValid && !isEmpty(touched)) {
          onClick();
        }
      }, HANDLE_SUBMIT_TIMEOUT);
    }
    if (!withOutSubmitAction) {
      handleSubmit();
    }
    timeout = setTimeout(() => setClicked(false), HANDLE_SUBMIT_TIMEOUT);
  };

  useEffect(() => cleanupFunction, []);

  if (animated) {
    return (
      <ButtonWithIcon
        variant="primary"
        type="submit"
        text={children}
        onClick={handleOnClick}
        disabled={!isValid || clicked}
        small
        {...restProps}
      >
        {icon}
      </ButtonWithIcon>
    );
  }

  if (withArrows) {
    return (
      <ButtonWithArrows
        variant="primary"
        type="submit"
        text={children}
        onClick={handleOnClick}
        disabled={!isValid || clicked}
        size="lg"
        {...restProps}
      >
        {children}
      </ButtonWithArrows>
    );
  }

  return (
    <Button
      variant="primary"
      type="submit"
      onClick={handleOnClick}
      disabled={!isValid || clicked || !areFormDependenciesReady}
      {...restProps}
    >
      {children}
    </Button>
  );
};

/*       Proptypes        */

Form.propTypes = { notifyOnFormErrors: PropTypes.bool, ...withDefaultProps() };

Form.Group.propTypes = { ...withDefaultProps() };

Form.Label.propTypes = { ...withDefaultProps() };

Form.Input.propTypes = {
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};
Form.Toggle.propTypes = {
  onChange: PropTypes.func,

  ...withDefaultProps(),
};

Form.Password.propTypes = { ...withDefaultProps() };

Form.Checkbox.propTypes = {
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};

Form.RadioButton.propTypes = {
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};

Form.MultiCheckbox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ...withDefaultProps(),
};

Form.DependentField.propTypes = {
  dependencyName: PropTypes.string.isRequired,
  calculateOptions: PropTypes.func.isRequired,
  Component: PropTypes.oneOf([Form.MultiSelect, Form.MultiCheckbox]),
  ...withDefaultProps(),
};

Form.DatePicker.propTypes = {
  options: PropTypes.object,
};

Form.DateRangePicker.propTypes = {
  relativePeriods: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      durationInDays: PropTypes.number.isRequired,
    }),
  ),
};

Form.DictionaryField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};

Form.GroupVertical.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
};

Form.GroupHorizontal.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  required: PropTypes.bool,
  style: PropTypes.object,
  tooltipText: PropTypes.string,
};

Form.FileUpload.propTypes = {
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};

Form.Submit.propTypes = {
  ...withDefaultProps(),
  animated: PropTypes.bool,
  icon: PropTypes.node,
  withArrows: PropTypes.bool,
};

Form.Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,

  ...withDefaultProps(),
};

Form.MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};

Form.MultiSelectSalon.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  ...withDefaultProps(),
};

export default Form;
