import CoreServiceRambla from './CoreServiceRambla';

class ProductService extends CoreServiceRambla {
  constructor(endpoint) {
    super(endpoint);
  }

  // parent products
  fetchParentProducts() {
    return this.api.get('/parent-products');
  }

  createParentProduct(name, description, details, brand_uid, category_uid) {
    return this.api.post('/parent-products', {
      name,
      description,
      details,
      brand_uid,
      category_uid,
    });
  }

  updateParentProduct(uid, name, description, details, category_uid) {
    return this.api.patch(`/parent-products/${uid}`, {
      name,
      description,
      details,
      category_uid,
    });
  }

  deleteParentProduct(uid) {
    return this.api.delete(`/parent-products/${uid}`);
  }

  // child products
  fetchChildProducts() {
    return this.api.get('/child-products');
  }

  createChildProduct(
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  ) {
    return this.api.post('/child-products', {
      product_code,
      picture_id,
      parent_product_uid,
      inventory_uid,
      color_uid,
      size_uid,
      prices,
    });
  }

  updateChildProduct(
    uid,
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  ) {
    return this.api.patch(`/child-products/${uid}`, {
      product_code,
      picture_id,
      parent_product_uid,
      inventory_uid,
      color_uid,
      size_uid,
      prices,
    });
  }

  deleteChildProduct(uid) {
    return this.api.delete(`/child-products/${uid}`);
  }

  // colors
  fetchColors() {
    return this.api.get('/product-colors');
  }

  createColor(name, hex_value, brand_uid) {
    return this.api.post('/product-colors', {
      name,
      hex_value,
      brand_uid,
    });
  }

  updateColor(uid, name, hex_value) {
    return this.api.patch(`/product-colors/${uid}`, {
      name,
      hex_value,
    });
  }

  deleteColor(uid) {
    return this.api.delete(`/product-colors/${uid}`);
  }

  generateColors() {
    return this.api.post('/product-colors/generate');
  }

  // sizes
  fetchSizes() {
    return this.api.get('/product-sizes');
  }

  createSize(name, brand_uid) {
    return this.api.post('/product-sizes', {
      name,
      brand_uid,
    });
  }

  updateSize(uid, name) {
    return this.api.patch(`/product-sizes/${uid}`, {
      name,
    });
  }

  deleteSize(uid) {
    return this.api.delete(`/product-sizes/${uid}`);
  }

  generateSizes() {
    return this.api.post('/product-sizes/generate');
  }

  // categories
  fetchCategories() {
    return this.api.get('/product-categories');
  }

  createCategory(name, brand_uid) {
    return this.api.post('/product-categories', {
      name,
      brand_uid,
    });
  }

  updateCategory(uid, name) {
    return this.api.patch(`/product-categories/${uid}`, {
      name,
    });
  }

  deleteCategory(uid) {
    return this.api.delete(`/product-categories/${uid}`);
  }

  generateCategories() {
    return this.api.post('/product-categories/generate');
  }

  // inventories
  fetchInventories() {
    return this.api.get('/product-inventories');
  }

  createInventory(name, brand_uid) {
    return this.api.post('/product-inventories', {
      name,
      brand_uid,
    });
  }

  updateInventory(uid, name) {
    return this.api.patch(`/product-inventories/${uid}`, {
      name,
    });
  }

  deleteInventory(uid) {
    return this.api.delete(`/product-inventories/${uid}`);
  }

  generateInventories() {
    return this.api.post('/product-inventories/generate');
  }

  // favorite products
  fetchFavoriteProducts() {
    return this.api.get('/favorite-products');
  }

  deleteFavoriteProduct(uid) {
    return this.api.delete(`/favorite-products/${uid}`);
  }

  addFavoriteProduct(uids, owner) {
    return this.api.post('/favorite-products', {
      child_product_uids: uids,
      owner,
    });
  }
}

const instance = new ProductService('/v1');

export default instance;
