import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'connected-react-router';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

import history from 'utils/history';
import { DndContext } from '@dnd-kit/core';
import App from './containers/App/app';
import store from './configureStore';

import './i18n';
import 'assets/scss/theme.scss';

const MOUNT_NODE = document.getElementById('root');

// This prevents from reloading page after every code change
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

ReactDOM.render(
  <Provider store={store}>
    <DndContext>
      <ConfirmModal />
      <ReduxToastr
        timeOut={3000}
        position="top-right"
        progressBar
        transitionIn="bounceInDown"
        transitionOut="bounceOutUp"
      />
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </DndContext>
  </Provider>,
  MOUNT_NODE,
);
