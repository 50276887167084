import produce from 'immer';
import * as types from './constants';

export const initialState = {
  availableProducts: [],
  lookTemplates: {
    fetched: false,
    data: [],
  },
  favouriteProducts: {
    fetched: true,
    data: [],
  },
  adminLooks: {
    fetched: false,
    data: [],
  },
  userLooks: {
    fetched: false,
    data: [],
  },
  adminLook: {
    fetched: false,
    data: null,
  },
  look: {
    fetched: false,
    data: null,
  },
  filteredProducts: [],
};

const looksReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.GET_USER_LOOKS_SUCCESS:
        draft.userLooks.data = payload;
        draft.userLooks.fetched = true;
        break;
      case types.GET_ADMIN_LOOKS_SUCCESS:
        draft.adminLooks.data = payload;
        draft.adminLooks.fetched = true;
        break;
      case types.GET_LOOK:
        draft.look.data = null;
        draft.look.fetched = false;
        break;
      case types.GET_LOOK_SUCCESS:
        draft.look.data = payload;
        draft.look.fetched = true;
        break;
      case types.GET_ADMIN_LOOK:
        draft.adminLook.data = null;
        draft.adminLook.fetched = false;
        break;
      case types.GET_ADMIN_LOOK_SUCCESS:
        draft.adminLook.data = payload;
        draft.adminLook.fetched = true;
        break;
      case types.GET_LOOK_TEMPLATES_SUCCESS:
        draft.lookTemplates.data = payload;
        draft.lookTemplates.fetched = true;
        break;
      case types.DELETE_LOOK_SUCCESS:
        draft.userLooks.data = draft.userLooks.data.filter(look => look.uid !== payload);
        break;
      case types.DELETE_ADMIN_LOOK_SUCCESS:
        draft.adminLooks.data = draft.adminLooks.data.filter(look => look.uid !== payload);
        break;
      case types.UPDATE_LOOK_SUCCESS:
        draft.look.data = { ...draft.look.data, ...payload };
        break;
      case types.UPDATE_ADMIN_LOOK_SUCCESS:
        draft.adminLook.data = { ...draft.adminLook.data, ...payload };
        break;
      // OLD BELOW: REFACTOR
      case types.GET_AVAILABLE_PRODUCTS_SUCCESS:
        draft.availableProducts = payload;
        break;
      case types.SEARCH_PRODUCTS_SUCCESS:
        draft.filteredProducts = payload;
        break;
      case types.UPDATE_LOOK_COVER_PHOTO:
        draft.look.data.cover_photos = [
          {
            cover_photo:
              'https://www.identixweb.com/wp-content/uploads/2019/03/URL-Short-Link-Generator.png',
            uid: '123',
          },
        ];
        break;
      case types.UPDATE_LOOK_COVER_PHOTO_SUCCESS:
        draft.look.data.cover_photo = payload;
        break;
      case types.UPDATE_LOOK_IS_PRIVATE_SUCCESS:
        draft.look.data.is_private = payload;
        break;
      // case types.UPDATE_LOOK_IS_EDITABLE_SUCCESS:
      //   draft.look.data.is_editable = payload;
      //   break;
      case types.ADD_PRODUCTS_TO_LOOK_SUCCESS:
        draft.look.data.products = [...draft.look.data.products, ...payload];
        break;
      case types.REMOVE_PRODUCT_FROM_LOOK_SUCCESS: {
        const index = draft.look.data.products.findIndex(
          product => product._id === payload.productId,
        );
        if (index !== -1) draft.look.data.products.splice(index, 1);
        break;
      }
      case types.GET_COVER_PHOTOS_SUCCESS:
        draft.look.data.cover_photos = payload;
        break;
      case types.ADD_COVER_PHOTO:
        draft.look.data.cover_photos.push(payload);
        break;
      case types.REMOVE_COVER_PHOTO: {
        // TODO: remove cover photo from state
        const index = draft.look.data.cover_photos.findIndex(
          coverPhoto => coverPhoto.uid === payload,
        );
        if (index !== -1) draft.look.data.cover_photos.splice(index, 1);
        break;
      }
      case types.CLEAR_LOOK:
        draft.look.data = null;
        draft.look.fetched = false;
        break;
      case types.CLEAR_ADMIN_LOOK:
        draft.adminLook.data = null;
        draft.adminLook.fetched = false;
        break;
      default:
        return state;
    }
  });

export default looksReducer;
