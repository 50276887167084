import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Card, Container, Col, Row, Nav, NavItem } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { generatePath, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Page } from 'components';
import {
  BRAND_INFORMATION,
  CHECKOUT_CONFIG,
  DASHBOARD,
  ONBOARDING,
  THEME_CUSTOMIZATION,
} from 'constants/routes';
import { ramblaIsOnboardingProcessFinishedSelector } from 'redux/brand/selectors';
import { roleSelector } from 'redux/auth/selectors';
import * as Roles from 'constants/roles';
import BrandInformationPage from './brandInformationPage';
import ThemeCustomizationPage from './themeCustomizationPage';
import CheckoutConfigPage from './checkoutConfigPage';
import { ReactComponent as BrandInfoIcon } from '../../assets/img/illustrations/brand-information.svg';
import { ReactComponent as ThemeIcon } from '../../assets/img/illustrations/theme-customization.svg';
import { ReactComponent as CheckoutType } from '../../assets/img/illustrations/checkout-type.svg';

const navTabs = [
  {
    key: 'BRAND_INFORMATION',
    label: 'brandInformation',
    Icon: BrandInfoIcon,
    route: BRAND_INFORMATION,
  },
  {
    key: 'THEME_CUSTOMIZATION',
    label: 'themeCustomization',
    Icon: ThemeIcon,
    route: THEME_CUSTOMIZATION,
  },
  {
    key: 'CHECKOUT_CONFIG',
    label: 'checkoutConfiguration',
    Icon: CheckoutType,
    route: CHECKOUT_CONFIG,
  },
];

const Tabs = ({ t }) => (
  <Nav variant="tabs" className="d-flex justify-content-between align-items-center mb-4">
    <div className="d-flex">
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active font-weight-bold"
          to={generatePath(BRAND_INFORMATION)}
          style={{ fontSize: 16 }}
        >
          {t('dashboard.brandInformation')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active font-weight-bold"
          exact
          to={generatePath(THEME_CUSTOMIZATION)}
          style={{ fontSize: 16 }}
        >
          {t('dashboard.themeCustomization')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link"
          activeClassName="active font-weight-bold"
          exact
          to={generatePath(CHECKOUT_CONFIG)}
          style={{ fontSize: 16 }}
        >
          {t('dashboard.checkoutConfiguration')}
        </NavLink>
      </NavItem>
    </div>
  </Nav>
);

const DashboardPage = () => {
  const { t } = useTranslation(['pages']);
  const history = useHistory();
  const location = useLocation();
  const isOnboardingProcessFinished = useSelector(ramblaIsOnboardingProcessFinishedSelector);
  const role = useSelector(roleSelector);

  const isRetailer = role === Roles.RETAILER;

  useEffect(() => {
    if (!isOnboardingProcessFinished && !isRetailer) {
      history.push(ONBOARDING);
    }
  }, [isOnboardingProcessFinished]);

  return (
    <Page
      title={t('dashboard.dashboard')}
      description={t('dashboard.customizeYourBrand')}
      withBackButton={false}
    >
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {location.pathname === DASHBOARD ? (
        <Container className="m-0 p-3" style={{ maxWidth: '100%' }}>
          <Row className="row-cols-1 row-cols-md-3 row-cols-lg-3 g-4">
            {navTabs.map(({ key, label, Icon, route }) => (
              <Col key={key}>
                <Card
                  className="h-100 d-flex flex-column justify-content-start align-items-center p-4"
                  onClick={() => history.push(route)}
                  role="button"
                >
                  <h2 className="text-center mb-4">{t(`dashboard.${label}`)}</h2>
                  <div
                    style={{
                      flex: '1 0 auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      padding: '20px 0',
                    }}
                  >
                    <Icon style={{ width: '100%', height: 'auto' }} />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <>
          <Tabs t={t} />

          <Switch>
            <Route exact path={BRAND_INFORMATION} component={BrandInformationPage} />
            <Route exact path={THEME_CUSTOMIZATION} component={ThemeCustomizationPage} />
            <Route exact path={CHECKOUT_CONFIG} component={CheckoutConfigPage} />
          </Switch>
        </>
      )}
    </Page>
  );
};

Tabs.propTypes = {
  t: PropTypes.func,
};

export default DashboardPage;
