import styled from 'styled-components/macro';
import { media } from 'utils/rwd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const TitleWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.45);
  position: relative;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`;

export const Icon = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  justify-content: center;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 0.25rem;
  width: 60px;
  min-width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Footer = styled.div`
  z-index: 1000;
  left: 0;
  width: 100%;
  min-height: 75px;
  position: fixed;
  border-top: 2px solid #e9ecef;
  bottom: 0;
  padding: 1rem 30px;
  background-color: white;
  display: flex;
  align-items: center;
  ${media.sm`
  left: ${({ sideBarCollapsed }) => (sideBarCollapsed ? '66px' : '250px')};
  width: ${({ sideBarCollapsed }) =>
    sideBarCollapsed ? 'calc(100% - 66px)' : 'calc(100% - 250px)'};
`}
`;
