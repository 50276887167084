import LooksService from 'api/services/LooksService';
import { push } from 'connected-react-router';
import * as hints from 'constants/lookFeatureHints';
import { ADMIN_LOOKS, RETAILER_LOOKS } from 'constants/routes';
import i18n from 'i18n';
import { toastr } from 'utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { regionsSelector } from 'redux/auth/selectors';
// import { uploadMedia } from 'utils/mediaResourceUtils';
import * as actions from './actions';
import * as types from './constants';
import { lookProductsSelector, singleLookSelector } from './selectors';

function* getUserLooks() {
  const looks = yield call([LooksService, 'fetchRetailerLooks']);

  yield put(actions.getUserLooksSuccess(looks));
}

function* getAdminLooks() {
  const looks = yield call([LooksService, 'fetchAdminLooks']);

  yield put(actions.getAdminLooksSuccess(looks));
}

function* getSingleLook(action) {
  const { payload: uid } = action;

  const look = yield call([LooksService, 'fetchLook'], uid);

  yield put(actions.getSingleLookSuccess(look));
}

function* getAdminLook(action) {
  const { payload: uid } = action;

  const look = yield call([LooksService, 'fetchBrandLook'], uid);

  yield put(actions.getAdminLookSuccess(look));
}

function* createLookDraft() {
  const { uid } = yield call([LooksService, 'createLookDraft']);

  yield put(push(`look/${uid}`));
}

function* createBrandLook() {
  const { uid } = yield call([LooksService, 'createBrandLook']);

  yield put(push(`admin-look/${uid}`));
}

function* createLookFromTemplate(action) {
  const { payload: uid } = action;
  const response = yield call([LooksService, 'createLookFromTemplate'], uid);

  yield put(push(`look/${response.uid}`));
}

function* deleteLook(action) {
  const { payload: uid } = action;

  yield call([LooksService, 'deleteLook'], uid);

  yield put(actions.deleteLookSuccess(uid));
  yield put(push(RETAILER_LOOKS));
}

function* deleteAdminLook(action) {
  const { payload: uid } = action;

  yield call([LooksService, 'deleteAdminLook'], uid);

  yield put(actions.deleteAdminLookSuccess(uid));
  yield put(push(ADMIN_LOOKS));
}

function* updateLook(action) {
  const {
    payload: { values },
  } = action;

  try {
    const { store: updatedLook } = yield call([LooksService, 'updateLook'], values);

    yield put(actions.updateLookSuccess(updatedLook));
    yield put(push(RETAILER_LOOKS));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

function* updateAdminLook(action) {
  const {
    payload: { values },
  } = action;

  try {
    const { store: updatedLook } = yield call([LooksService, 'updateBrandLook'], values);

    yield put(actions.updateAdminLookSuccess(updatedLook));
    yield put(push(ADMIN_LOOKS));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

// OLD BELOW: REFACTOR

function* getAvailableProducts() {
  try {
    const regions = yield select(regionsSelector);

    const products = yield call([LooksService, 'fetchAvailableProducts'], regions[0].uid);
    yield put(actions.getAvailableProductsSuccess(products));
  } catch (err) {
    toastr.error(i18n.t('toastr:getProductsError'));
  }
}

function* getLookTemplates() {
  const templates = yield call([LooksService, 'fetchLookTemplates']);
  yield put(actions.getLookTemplatesSuccess(templates));
}

function* searchProducts(action) {
  const regions = yield select(regionsSelector);

  const { payload } = action;
  const products = yield call([LooksService, 'searchProducts'], {
    regionUid: regions[0].uid,
    productName: payload,
  });
  yield put(actions.searchProductsSuccess(products));
}

function* updateCoverPhoto(action) {
  const {
    payload: { image, aspectRatio },
    isRetry,
  } = action;
  const { data: look } = yield select(singleLookSelector);

  try {
    // const { public_id } = yield call(uploadMedia, [image]);

    // const newPhoto = yield call([LooksService, 'addCoverPhotos'], look._id, public_id);

    const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
      aspect_ratio: aspectRatio,
      feature_hints: {
        [hints.COVER_PHOTO_HINT]: true,
      },
    });

    yield put(actions.updateLookSuccess(updatedlook));
    // yield put(actions.addCoverPhoto(newPhoto));

    toastr.success('Updated successfully');
  } catch (e) {
    if (!isRetry) {
      yield put(actions.updateLookCoverPhoto({ image, aspectRatio }, true));
      return;
    }
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

function* getCoverPhotos() {
  const { data: look } = yield select(singleLookSelector);

  const coverPhotos = yield call([LooksService, 'fetchCoverPhotos'], look.short_id);

  yield put(actions.getCoverPhotosSuccess(coverPhotos));
}

function* removeCoverPhoto(action) {
  const { payload: photoId } = action;

  try {
    yield call([LooksService, 'removeCoverPhoto'], photoId);
    yield put(actions.removeCoverPhotoSuccess(photoId));
  } catch {
    toastr.error(i18n.t('toastr:error'), `Unable to update, please try again`);
  }
}

function* addProductsToLook(action) {
  try {
    const { data: look } = yield select(singleLookSelector);

    const newProducts = yield call([LooksService, 'addProductsToLook'], action.payload);

    if (!look.feature_hints[hints.PRODUCTS_HINT]) {
      const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
        feature_hints: {
          [hints.PRODUCTS_HINT]: true,
        },
      });
      yield put(actions.updateLookSuccess(updatedlook));
    } else {
      yield put(actions.addProductsToLookSuccess(newProducts));
    }

    toastr.success('Updated successfully');
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

function* createLookFromDraft() {
  const { data: look } = yield select(singleLookSelector);

  try {
    const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
      feature_hints: {
        [hints.SAVED_HINT]: true,
      },
    });

    yield put(actions.updateLookSuccess(updatedlook));

    toastr.success('Look added. New looks are on the top of the list');

    // if (updatedlook.is_template) {
    //   yield put(push(LOOK_TEMPLATE));
    // } else {
    //   yield put(push(RETAILER_LOOKS));
    // }
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

function* updateIsPrivate(action) {
  const { payload: isPrivate } = action;
  const { data: look } = yield select(singleLookSelector);

  try {
    yield call([LooksService, 'updateIsPrivate'], look._id, isPrivate);

    yield put(actions.updateIsPrivateSuccess(isPrivate));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}

function* updateIsEditable(action) {
  const { payload: isEditable } = action;
  // const { data: look } = yield select(singleLookSelector);

  try {
    // yield call([LooksService, 'updateIsEditable'], look._id, isEditable);

    yield put(actions.updateIsPrivateSuccess(isEditable));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unable to update, please try again');
  }
}
function* removeProductFromLook(action) {
  const { data: look } = yield select(singleLookSelector);
  const lookProducts = yield select(lookProductsSelector);
  const {
    payload: { productId },
  } = action;

  try {
    yield call([LooksService, 'deleteProduct'], look._id, productId);

    // deleting last product from draft store
    if (lookProducts.length === 1 && look.is_draft) {
      const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
        feature_hints: {
          [hints.PRODUCTS_HINT]: false,
        },
      });
      yield put(actions.updateLookSuccess(updatedlook));
    }

    yield put(actions.removeProductFromLookSuccess(productId));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), 'Unexpected error, please try again.');
  }
}

export const looksSagas = [
  takeLatest(types.GET_USER_LOOKS, getUserLooks),
  takeLatest(types.GET_ADMIN_LOOKS, getAdminLooks),
  takeLatest(types.LOOK_CREATE_NEW, createLookDraft),
  takeLatest(types.CREATE_BRAND_LOOK, createBrandLook),
  takeLatest(types.CREATE_LOOK_DRAFT, createLookDraft),
  takeLatest(types.CREATE_LOOK_FROM_TEMPLATE, createLookFromTemplate),
  takeLatest(types.DELETE_LOOK, deleteLook),
  takeLatest(types.DELETE_ADMIN_LOOK, deleteAdminLook),
  takeLatest(types.GET_LOOK, getSingleLook),
  takeLatest(types.GET_ADMIN_LOOK, getAdminLook),
  takeLatest(types.UPDATE_LOOK, updateLook),
  takeLatest(types.UPDATE_ADMIN_LOOK, updateAdminLook),
  takeLatest(types.GET_LOOK_TEMPLATES, getLookTemplates),
  takeLatest(types.GET_AVAILABLE_PRODUCTS, getAvailableProducts),
  takeLatest(types.SEARCH_PRODUCTS, searchProducts),
  takeLatest(types.UPDATE_LOOK_COVER_PHOTO, updateCoverPhoto),
  takeLatest(types.REMOVE_COVER_PHOTO, removeCoverPhoto),
  takeLatest(types.ADD_PRODUCTS_TO_LOOK, addProductsToLook),
  takeLatest(types.CREATE_LOOK_FROM_DRAFT, createLookFromDraft),
  takeLatest(types.UPDATE_LOOK_IS_PRIVATE, updateIsPrivate),
  takeLatest(types.UPDATE_LOOK_IS_EDITABLE, updateIsEditable),
  takeLatest(types.REMOVE_PRODUCT_FROM_LOOK, removeProductFromLook),
  takeLatest(types.GET_COVER_PHOTOS, getCoverPhotos),
];
