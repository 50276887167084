/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'components';
import { Formik, FieldArray } from 'formik';
import { FaPlus, FaMinus } from 'react-icons/fa';
import updateUrlPreview from 'utils/updateUrlPreview';
import { childProduct } from '../../api/models/Product';
import validationSchema from './schemas/index';

const productKeys = Object.keys(childProduct);
const quantityOptions = [
  { label: '&qty=v', value: 'qtyStandard' },
  { label: '&QTY=v', value: 'qtyUppercase' },
  { label: '&q=v', value: 'qValue' },
  { label: '&quantity=v', value: 'quantityFull' },
  { label: ':v', value: 'colonValue' },
];

const CheckoutConfigForm = ({ initialValues, handleSubmit }) => {
  const { t } = useTranslation(['pages']);
  const [urlPreview, setUrlPreview] = useState('');

  useEffect(() => {
    const preview = updateUrlPreview(initialValues);
    setUrlPreview(preview);
  }, [initialValues]);

  const handleValueChange = (field, value, setValues) => {
    setValues(prevValues => {
      const newValues = JSON.parse(JSON.stringify(prevValues));

      const fields = field.split('.');
      let current = newValues;
      for (let i = 0; i < fields.length - 1; i++) {
        current = current[fields[i]];
      }
      current[fields[fields.length - 1]] = value;

      const preview = updateUrlPreview(newValues);
      setUrlPreview(preview);

      return newValues;
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema.checkout}
      enableReinitialize
    >
      {({ values, setFieldValue, setValues, isValid, isSubmitting }) => (
        <Form>
          {urlPreview && (
            <Form.Group>
              <Form.Label>{t('checkoutConfiguration.previewUrl')}</Form.Label>
              <p style={{ color: '#BE1622' }} className="d-block">
                {urlPreview}
              </p>
            </Form.Group>
          )}
          <Form.GroupHorizontal style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <div style={{ flexBasis: '20%' }}>
              <Form.Label>{t('checkoutConfiguration.destination')}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
            </div>
            <Form.Input
              style={{ flexBasis: '100%' }}
              name="destination"
              type="text"
              required
              placeholder={t('checkoutConfiguration.destinationTooltip')}
              value={values.destination}
              onChange={e => handleValueChange('destination', e.target.value, setValues)}
            />
          </Form.GroupHorizontal>
          <Form.Group>
            <>
              <Form.Label>{t('checkoutConfiguration.locationId')}</Form.Label>
            </>
            <Form.Input
              name="locationId"
              type="text"
              required
              placeholder={t('checkoutConfiguration.locationIdTooltip')}
              value={values.locationId}
              onChange={e => handleValueChange('locationId', e.target.value, setValues)}
            />
          </Form.Group>
          <Form.Group>
            <>
              <Form.Label>{t('checkoutConfiguration.publishId')}</Form.Label>
            </>
            <Form.Input
              name="publisherId"
              type="text"
              required
              placeholder={t('checkoutConfiguration.publishIdTooltip')}
              value={values.publishId}
              onChange={e => handleValueChange('publishId', e.target.value, setValues)}
            />
          </Form.Group>
          <Form.Group>
            <>
              <Form.Label>{t('checkoutConfiguration.trackingBrandId')}</Form.Label>
            </>
            <Form.Input
              name="trackingBrandId"
              type="text"
              placeholder={t('checkoutConfiguration.trackingBrandIdTooltip')}
              value={values.trackingBrandId}
              onChange={e => handleValueChange('trackingBrandId', e.target.value, setValues)}
            />
          </Form.Group>
          <Form.Group>
            <>
              <Form.Label>{t('checkoutConfiguration.redirectUrlIncluded')}</Form.Label>
            </>
            <div className="d-flex align-items-center">
              <Form.Checkbox
                name="redirectUrlIncluded"
                onChange={e => setFieldValue('redirectUrlIncluded', e.target.checked)}
              />
              <Form.Input
                name="redirectUrl"
                type="text"
                placeholder={
                  values.redirectUrlIncluded ? t('checkoutConfiguration.redirectUrl') : null
                }
                value={values.redirectUrl}
                disabled={!values.redirectUrlIncluded}
                onChange={e => handleValueChange('redirectUrl', e.target.value, setValues)}
              />
            </div>
          </Form.Group>
          <hr />
          <FieldArray name="customParameters">
            <div>
              {values.customParameters?.map((field, index) => (
                <div key={index} className="d-flex" style={{ gap: '10px' }}>
                  <Form.Group className="w-50">
                    <>
                      <Form.Label>{`${t('checkoutConfiguration.customParameter')} #${index +
                        1}`}</Form.Label>
                    </>
                    <Form.Input
                      name={`customParameters.${index}.key`}
                      placeholder={t('checkoutConfiguration.customParameter')}
                      onChange={e =>
                        handleValueChange(
                          `customParameters.${index}.key`,
                          e.target.value,
                          setValues,
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className="w-50">
                    <>
                      <Form.Label>{`${t('checkoutConfiguration.customParameterValue')} #${index +
                        1}`}</Form.Label>
                    </>
                    <Form.Input
                      name={`customParameters.${index}.value`}
                      value={field.value}
                      placeholder={t('checkoutConfiguration.customParameterValueTooltip')}
                      onChange={e =>
                        handleValueChange(
                          `customParameters.${index}.value`,
                          e.target.value,
                          setValues,
                        )
                      }
                    />
                  </Form.Group>
                </div>
              ))}
            </div>
          </FieldArray>
          <Form.Group className="w-100 d-flex" style={{ gap: '10px' }}>
            <Button
              className="p-3 d-flex align-items-center justify-content-center"
              onClick={() => {
                setFieldValue('customParameters', [
                  ...values.customParameters,
                  { key: '', value: '' },
                ]);
              }}
            >
              <FaPlus className="m-0 p-0" />
            </Button>
            <Button
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setFieldValue('customParameters', values.customParameters.slice(0, -1));
              }}
              disabled={values.customParameters.length === 1}
            >
              <FaMinus className="m-0 p-0" />
            </Button>
          </Form.Group>
          <hr />
          <FieldArray name="parameters">
            <div>
              {values.parameters?.map((parameter, index) => (
                <div key={index} className="d-flex" style={{ gap: '10px' }}>
                  <Form.Group className="w-50">
                    <>
                      <Form.Label>{`${t('checkoutConfiguration.productParameter')} #${index +
                        1}`}</Form.Label>
                    </>
                    <Form.Select
                      name={`parameters.${index}.productField`}
                      onChange={e =>
                        handleValueChange(`parameters.${index}.productField`, e.value, setValues)
                      }
                      options={productKeys.map(key => ({
                        label: key,
                        value: key,
                      }))}
                    />
                  </Form.Group>
                  <Form.Group className="w-50">
                    <>
                      <Form.Label>{`${t('checkoutConfiguration.parameterKey')} #${index +
                        1}`}</Form.Label>
                    </>
                    <Form.Input
                      name={`parameters.${index}.key`}
                      value={parameter.key}
                      placeholder={t('checkoutConfiguration.parameterKey')}
                      onChange={e =>
                        handleValueChange(`parameters.${index}.key`, e.target.value, setValues)
                      }
                    />
                  </Form.Group>
                </div>
              ))}
              <Form.Group className="w-100 d-flex" style={{ gap: '10px' }}>
                <Button
                  className="p-3 d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setFieldValue('parameters', [...values.parameters, { key: '' }]);
                  }}
                >
                  <FaPlus className="m-0 p-0" />
                </Button>
                <Button
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setFieldValue('parameters', values.parameters.slice(0, -1));
                  }}
                  disabled={values.parameters.length === 1}
                >
                  <FaMinus className="m-0 p-0" />
                </Button>
              </Form.Group>
            </div>
          </FieldArray>
          <Form.Group>
            <>
              <Form.Label>{t('checkoutConfiguration.quantity')}</Form.Label>
              <span style={{ color: 'red' }}>*</span>
            </>
            <Form.Select
              name="quantity"
              type="text"
              onChange={e => handleValueChange('quantity', e.label, setValues)}
              options={quantityOptions}
            />
          </Form.Group>
          <Form.Group style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Submit disabled={!isValid || isSubmitting}>
              {t('checkoutConfiguration.saveButton').toUpperCase()}
            </Form.Submit>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

CheckoutConfigForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
};

CheckoutConfigForm.defaultProps = {
  initialValues: {
    destination: '',
    parameters: [
      {
        productField: '',
        key: '',
      },
    ],
    customParameters: [
      {
        key: '',
        value: '',
      },
    ],
    redirectUrlIncluded: false,
  },
  handleSubmit: () => {},
};

export default CheckoutConfigForm;
