import { toastr } from 'utils';
import * as _ from 'lodash';
import AxiosProvider from './AxiosProvider';
import env from '../config';
import { getRamblaToken } from '../utils/tokenService';

class CoreApiRambla {
  constructor(baseUrl = env.apiBaseUrlRambla) {
    this.api = AxiosProvider(baseUrl);
    this.baseUrl = baseUrl;

    this.afterResponse = this.afterResponse.bind(this);
    this.beforeRequest = this.beforeRequest.bind(this);
    this.setInterceptors(this.beforeRequest, this.requestError, this.afterResponse);
  }

  setInterceptors(beforeRequest, requestError, afterResponse, responseError) {
    this.api.interceptors.request.use(beforeRequest, requestError);
    this.api.interceptors.response.use(afterResponse, responseError);
  }

  setEndpointUrl(url) {
    this.api.defaults.baseURL = `${this.baseUrl}/${url}`;
  }

  beforeRequest(config) {
    if ('requireAuth' in config && config.requireAuth === false) {
      return config;
    }

    const token = getRamblaToken();

    if (token) {
      const originalRequest = config;
      originalRequest.headers.Authorization = token;
      originalRequest.headers.Brand = '6a7L2kljoLD7Pwvkop8PR9OM';
      originalRequest.headers.Region = 'ojpkg4ObLORVkeK7BAP8eYJd';

      return originalRequest;
    }

    return new Error('User not logged in');
  }

  requestError(error) {
    throw error;
  }

  afterResponse(resp) {
    return resp.data;
  }

  responseError(error) {
    const notifyErrorsFlag = error.response && error.response.config.notifyErrors;
    const showNotifyErrors = notifyErrorsFlag === undefined || notifyErrorsFlag === true;
    if (error.response) {
      if (showNotifyErrors) {
        if (_.isArray(error.response)) {
          toastr.error('Error occured', error.response.join('\n'));
        } else {
          return error.response.data && _.isObject(error.response.data)
            ? toastr.error('Error occurred', parseErrorResponse(error.response.data))
            : toastr.error('Error occurred', 'Unexpected error, please try again.');
        }
      }
    } else {
      throw error;
    }

    const parseErrorResponse = err => {
      let errorPlain = '';
      Object.keys(err).forEach(key => {
        const errResponse = err[key].reduce((acc, val) => `${acc} ${val} `, '');
        errorPlain += `${key.toUpperCase()}: ${errResponse}}`;
      });
      return errorPlain;
    };
  }
}

export default CoreApiRambla;
