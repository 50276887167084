export const defaultProduct = {
  name: '',
  description: '',
  product_groups: [],
  details: '',
  highlighted: 0,
  color: null,
  materials: [],
  availability_date_start: null,
  availability_date_end: null,
  image_ids: [],
  images: [],
  ingedients: [],
  claims: [],
  sizes: [],
  videoUrl: '',
  types: [],
  brand: '',
  gtins: [],
  sku: '',
  size: null,
  recommended_usage: '',
  expiration: null,
  inventory: '',
  taxCategory: '',
  wholesale_price: null,
  retail_price: null,
  buzzwords: [],
  keywords: [],
  product_will_have_variants: true,
  vat_percentage: 20,
  commission: null,
  is_editable: true,
};

export const childProduct = {
  id: 0,
  uid: '',
  product_code: '',
  price: 0,
  picture_id: '',
  picture: '',
  parent_product: null,
  size: null,
  color: null,
  inventory: null,
  category: null,
  prices: [],
};

class Product {
  constructor(product = defaultProduct) {
    Object.assign(this, product);
  }
}

export default Product;
