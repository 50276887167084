import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import { brandSelector } from 'redux/brand/selectors';
import useEntityForm from 'hooks/useEntityForm';
import { createCategory, updateCategory } from 'redux/products/actions';

const CategoryForm = ({ type = 'create', initialValues, FormGroup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { validationSchema } = useEntityForm(type, 'category');
  const brand = useSelector(brandSelector);

  return (
    <Form
      initialValues={
        pick(initialValues, ['name', 'uid']) || {
          name: '',
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={({ uid, name }) =>
        type === 'update'
          ? dispatch(updateCategory(uid, name))
          : dispatch(createCategory(name, brand.data?.uid))
      }
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup required label={t('app:name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

CategoryForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
};

export default CategoryForm;
