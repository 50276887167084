import styled from 'styled-components/macro';

export const FilterNumber = styled.span`
  position: absolute;
  display: inline-block;
  bottom: 25px;
  border-radius: 50%;
  border: 2px solid;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
  background: white;
  font-size: 10px;
  line-height: 11px;
`;

export const FilterName = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const FilterButtonWrapper = styled.button.attrs({
  className: 'btn btn-white px-4 ml-2 py-0',
})`
  position: relative;
`;
