import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotFoundIcon } from '../../assets/svg/notFound.svg';

const PageNotFound = ({ location }) => {
  const { t } = useTranslation(['pages']);

  return (
    <Container className="d-flex align-items-center vh-100">
      <Row className="justify-content-center m-0 w-100">
        <Col xs="12" md="8" lg="6">
          <div style={{ padding: '16px 36px' }}>
            <h1 className="display-1 text-center text-uppercase my-5">404</h1>
            <div
              style={{
                maxWidth: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                margin: '48px 0',
              }}
            >
              <NotFoundIcon style={{ width: '100%', height: 'auto' }} />/
            </div>
            <h1 className="display-4 text-center text-uppercase mb-3">{t('pageNotFound.title')}</h1>
            {location?.state ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <p className="text-muted text-center">
                  {t('pageNotFound.page')}
                  <span>
                    <b className="text-secondary">{` ${
                      location.state.from.pathname !== '/' ? location.state.from.pathname : ''
                    } `}</b>
                  </span>
                  {t('pageNotFound.subtitle')}
                </p>
                <a href="/"> {t('pageNotFound.goBack')}</a>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
PageNotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PageNotFound;
