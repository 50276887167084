import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import countries from 'constants/countries';
import languages from 'constants/languages';
import * as Roles from 'constants/roles';
import useEntityForm from 'hooks/useEntityForm';
import { useUserRole } from 'hooks/useUserRole';
import { currencies } from 'constants/currenciesList';
import { createRegion, updateRegion } from 'redux/regions/actions';
import { brandSelector } from 'redux/brand/selectors';

const RegionForm = ({ type = 'create', initialValues, FormGroup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'app']);
  const { validationSchema } = useEntityForm(type, 'region');

  const userRole = useUserRole();
  const isSuperAdmin = userRole === Roles.DEFAULT_ROLE;
  const brand = useSelector(brandSelector);

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, ['uid', 'name', 'base_currency', 'country_code', 'base_language']),
        } || {
          name: null,
          base_currency: null,
          country_code: '',
          base_language: '',
          brand_uid: brand.data?.uid,
        }
      }
      onSubmit={({ uid, name, base_currency, base_language, country_code }) =>
        type === 'update'
          ? dispatch(
              updateRegion(uid, name, base_currency, base_language, country_code, brand.data?.uid),
            )
          : dispatch(
              createRegion({
                name,
                base_currency,
                base_language,
                country_code,
                brand_uid: brand.data?.uid,
              }),
            )
      }
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup required label={t('pages:regions.name')}>
          <Form.Input type="text" name="name" disabled={!isSuperAdmin} />
        </FormGroup>
        <FormGroup required label={t('pages:regions.currency')}>
          <Form.MultiSelect
            options={currencies.map(currency => ({
              value: currency.code,
              label: currency.description,
            }))}
            name="base_currency"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
        <FormGroup required label={t('pages:regions.country')}>
          <Form.MultiSelect
            options={countries.map(country => ({
              value: country.code,
              label: country.name,
            }))}
            name="country_code"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
        <FormGroup required label={t('pages:regions.language')}>
          <Form.MultiSelect
            options={languages.map(language => ({
              value: language.value,
              label: language.name,
            }))}
            name="base_language"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)} </Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

RegionForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
};

export default RegionForm;
