import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import { Set } from 'immutable';
import { getLabel } from 'components/Form/form';

import MenuList from './MenuList';

const SelectFilter = ({
  setFilter,
  name,
  tooltipText,
  column,
  preFilteredRows,
  filters,
  withCaption = false,
  className,
  wrapperStyle,
  customOptions,
  defaultValue,
}) => {
  const [filterValue, setValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value || defaultValue,
  );

  const options = React.useMemo(() => {
    if (customOptions) {
      return customOptions.map(option => ({
        label: option.label.toUpperCase(),
        value: option.value,
      }));
    }
    let emptySet = new Set();
    preFilteredRows.forEach(row => {
      emptySet = emptySet.add(row.original[column]);
    });
    const mappedValues = [...emptySet.values()].map(value => ({
      label: value.toUpperCase(),
      value,
    }));
    return mappedValues;
  }, [column, preFilteredRows]);

  const getValue = () => options.filter(option => filterValue === option.value);
  const value = getValue();

  return (
    <Form.Group style={wrapperStyle} className={className}>
      {withCaption && getLabel(name || column, tooltipText)}
      <Select
        closeMenuOnSelect
        filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
        options={options}
        isMulti={false}
        onChange={selectedOption => {
          setValue(selectedOption.value);
          setFilter(column, selectedOption.value || undefined);
        }}
        value={value}
        components={{ MenuList }}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            height: '44.39px',
          }),
        }}
      />
    </Form.Group>
  );
};

SelectFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  name: PropTypes.string,
  tooltipText: PropTypes.string,
  preFilteredRows: PropTypes.array.isRequired,
  withCaption: PropTypes.bool,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
  defaultValue: PropTypes.any,
  customOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default SelectFilter;
