import React from 'react';
import { toastr as toastrNotification } from 'react-redux-toastr';

import { ReactComponent as SuccessNotificationIcon } from '../assets/svg/notificationsIcons/check.svg';
import { ReactComponent as AlertNotificationIcon } from '../assets/svg/notificationsIcons/alert.svg';
import { ReactComponent as ErrorNotificationIcon } from '../assets/svg/notificationsIcons/error.svg';
import { openModalAction } from '../redux/modal/actions';
import { CONFIRM_MODAL_ID } from '../components/ConfirmModal/constants';

export const toastr = {
  success: (title, message, options) => {
    toastrNotification.success(title, message, { icon: <SuccessNotificationIcon />, ...options });
  },
  warning: (title, message, options) => {
    toastrNotification.warning(title, message, { icon: <AlertNotificationIcon />, ...options });
  },
  error: (title, message, options) => {
    toastrNotification.error(title, message, { icon: <ErrorNotificationIcon />, ...options });
  },
  info: (title, message, options) => {
    toastrNotification.info(title, message, { ...options });
  },
  confirm: (heading, { onClose, description, onOk, submitLabel, dispatch, closeLabel }) => {
    dispatch(
      openModalAction(CONFIRM_MODAL_ID, {
        buttonProps: {
          onClick: onOk,
          label: submitLabel || 'YES',
        },
        onClose,
        description,
        heading,
        onOk,
        closeLabel: closeLabel || 'NO',
      }),
    );
  },
};
