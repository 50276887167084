import React from 'react';

import { CardContainer } from 'components';
import { CheckoutConfigForm } from 'containers/forms';

const CheckoutConfigPage = () => (
  <CardContainer standard>
    <CheckoutConfigForm type="update" />
  </CardContainer>
);

export default CheckoutConfigPage;
