import React from 'react';
import * as route from 'constants/routes';
import StorefrontIcon from '@material-ui/icons/Storefront';
import i18n from 'i18n';

import { DEFAULT_ROLE, ADMIN, RETAILER } from 'constants/roles';

export const menuItems = [
  {
    id: '1',
    label: i18n.t('ownerPortal:menu.dashboard'),
    adminToolLabel: i18n.t('menu:dashboard'),
    icon: 'fe-home',
    link: route.DASHBOARD,
    roles: [DEFAULT_ROLE],
    items: [
      {
        id: '1.1',
        label: i18n.t('menu:brandInformation'),
        link: route.BRAND_INFORMATION,
        roles: [ADMIN],
      },
      {
        id: '1.2',
        label: i18n.t('menu:themeCustomization'),
        link: route.THEME_CUSTOMIZATION,
        roles: [ADMIN],
      },
      {
        id: '1.3',
        label: i18n.t('menu:checkoutConfiguration'),
        link: route.CHECKOUT_CONFIG,
        roles: [ADMIN],
      },
    ],
  },
  {
    id: '2',
    label: i18n.t('menu:products'),
    icon: 'fe-package',
    link: route.PRODUCTS,
    roles: [DEFAULT_ROLE, ADMIN],
    items: [
      {
        id: '2.1',
        label: i18n.t('menu:parentProducts'),
        link: route.PARENT_PRODUCTS,
        roles: [DEFAULT_ROLE, ADMIN],
      },
      {
        id: '2.2',
        label: i18n.t('menu:childProducts'),
        link: route.CHILD_PRODUCTS,
        roles: [DEFAULT_ROLE, ADMIN],
      },
      {
        id: '2.3',
        label: i18n.t('menu:colors'),
        link: route.COLORS,
        roles: [DEFAULT_ROLE, ADMIN],
      },
      {
        id: '2.4',
        label: i18n.t('menu:sizes'),
        link: route.SIZES,
        roles: [DEFAULT_ROLE, ADMIN],
      },
      {
        id: '2.5',
        label: i18n.t('menu:categories'),
        link: route.CATEGORIES,
        roles: [DEFAULT_ROLE, ADMIN],
      },
      {
        id: '2.6',
        label: i18n.t('menu:inventories'),
        link: route.INVENTORIES,
        roles: [DEFAULT_ROLE, ADMIN],
      },
    ],
  },
  {
    id: '3',
    label: i18n.t('menu:sellers'),
    icon: 'fe-users',
    link: route.RETAILERS,
    roles: [ADMIN],
  },
  {
    id: '4',
    label: i18n.t('menu:stores'),
    icon: 'fa-shopping-cart',
    renderIcon: () => (
      <div className="fe">
        <StorefrontIcon />
      </div>
    ),
    link: route.STORES,
    roles: [RETAILER],
  },
  {
    id: '5',
    label: i18n.t('menu:regions'),
    icon: 'fe-map',
    link: route.REGIONS,
    roles: [ADMIN],
  },
  {
    id: '6',
    label: i18n.t('menu:brandManager'),
    icon: 'fe-git-branch',
    link: route.BRAND_MANAGER,
    roles: [DEFAULT_ROLE],
    items: [
      {
        id: '6.1',
        label: i18n.t('menu:adminLooks'),
        icon: 'fe-layout',
        link: route.ADMIN_LOOKS,
        roles: [ADMIN],
      },
      {
        id: '6.2',
        icon: 'fe-image',
        label: i18n.t('menu:defaultLookImage'),
        link: route.DEFAULT_LOOK_IMAGE,
      },
      {
        id: '6.3',
        icon: 'fe-monitor',
        label: i18n.t('menu:welcomeScreen'),
        link: route.WELCOME_SCREENS,
      },
      {
        id: '6.4',
        icon: 'fe-info',
        label: i18n.t('menu:howToHub'),
        link: route.HOW_TO_HUB,
        roles: [ADMIN],
      },
      {
        id: '6.5',
        icon: 'fe-info',
        label: i18n.t('menu:howToHubRetailer'),
        link: route.HOW_TO_HUB_RETAILER,
        roles: [RETAILER],
      },
      {
        id: '6.6',
        icon: 'fe-folder',
        label: i18n.t('menu:brandContent'),
        link: route.BRAND_CONTENT,
      },
    ],
  },
  {
    id: '7',
    label: i18n.t('menu:retailerLooks'),
    icon: 'fe-layout',
    link: route.RETAILER_LOOKS,
    roles: [RETAILER],
  },
  {
    id: '8',
    label: i18n.t('menu:favoriteProducts'),
    icon: 'fe-star',
    link: route.FAVORITE_PRODUCTS,
    roles: [RETAILER],
  },
  // {
  //   id: '10',
  //   label: i18n.t('menu:activityTracker'),
  //   icon: 'fe-bar-chart-2',
  //   link: route.LANDING_PAGE,
  //   roles: [DEFAULT_ROLE],
  // },
  // TODO: uncomment when locations are ready
  // {
  //   id: '6',
  //   label: i18n.t('menu:locations'),
  //   icon: 'fe-map-pin',
  //   roles: [DEFAULT_ROLE],
  //   link: route.MY_LOCATIONS,
  // },
];
