import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';
import { useOnEscape, useOnClickOutside, useTabbing, useIsomorphicLayoutEffect } from 'hooks';
import withDefaultProps from 'helpers/withDefaultProps';
import { toastr } from 'utils';
import { CloseButton } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Overlay,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalSubTitle,
} from './styles';

let popupIdCounter = 0;

const getRootPopup = () => {
  let PopupRoot = document.getElementById('popup-root');

  if (PopupRoot === null) {
    PopupRoot = document.createElement('div');
    PopupRoot.setAttribute('id', 'popup-root');
    document.body.appendChild(PopupRoot);
  }

  return PopupRoot;
};

export const PopupContext = React.createContext();

export const Popup = forwardRef(
  (
    {
      workInProgress = false,
      trigger = null,
      onOpen = () => {},
      onClose = () => {},
      defaultOpen = false,
      open = undefined,
      disabled = false,
      closeOnDocumentClick = true,
      closeOnEscape = true,
      contentStyle = {},
      className = '',
      lockScroll = false,
      header = {},
      sidebar = false,
      closeButton = true,
      wide = false,
      footer,
      children,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(() => open || defaultOpen);
    const dispatch = useDispatch();
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    const focusedElBeforeOpen = useRef(null);
    const popupId = useRef(`popup-${++popupIdCounter}`);
    const { t } = useTranslation(['toastr']);

    const timeOut = useRef(0);

    useIsomorphicLayoutEffect(() => {
      if (isOpen) {
        focusedElBeforeOpen.current = document.activeElement;
        focusContentOnOpen();
        lockScrolll();
      } else {
        resetScroll();
      }
      return () => {
        clearTimeout(timeOut.current);
      };
    }, [isOpen]);

    // for uncontrolled popup we need to sync isOpen with open prop
    useEffect(() => {
      if (typeof open === 'boolean') {
        if (open) openPopup();
        else closePopup();
      }
    }, [open, disabled]);

    useImperativeHandle(ref, () => ({
      open: () => {
        openPopup();
      },
      close: () => {
        closePopup();
      },
      toggle: () => {
        togglePopup();
      },
    }));

    const openPopup = () => {
      if (isOpen || disabled) return;
      setIsOpen(true);
      setTimeout(onOpen, 0);
    };

    const handleClosePopup = () => {
      setIsOpen(false);
      focusedElBeforeOpen.current.focus();
      setTimeout(onClose, 0);
    };

    const closePopup = (force = false) => {
      if (!isOpen || disabled) return;
      if (!workInProgress || force) {
        handleClosePopup();
        return;
      }
      toastr.confirm(t('toastr:changesWillBeLost'), {
        onOk: () => {
          handleClosePopup();
        },
        dispatch,
      });
    };

    const togglePopup = event => {
      event.stopPropagation();
      if (!isOpen) openPopup();
      else closePopup();
    };

    const lockScrolll = () => {
      if (lockScroll) document.getElementsByTagName('body')[0].style.overflow = 'hidden'; // migrate to document.body
    };

    const resetScroll = () => {
      if (lockScroll) document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };

    const focusContentOnOpen = () => {
      const focusableEls = contentRef?.current?.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]',
      );
      const firstEl = Array.prototype.slice.call(focusableEls)[0];
      firstEl.focus();
    };

    /** **** HOOKS ***** */

    /* close modal using Esc key */
    useOnEscape(closePopup, closeOnEscape);
    /* use tab key to navigate trought clicable modal elements */
    useTabbing(contentRef, isOpen);
    useOnClickOutside(
      trigger ? [contentRef, triggerRef] : [contentRef],
      closePopup,
      closeOnDocumentClick,
    );

    /** **** CONTENT PARTS ***** */

    const renderTrigger = () => {
      const triggerProps = {
        key: 'T',
        ref: triggerRef,
        'aria-describedby': popupId.current,
      };
      triggerProps.onClick = togglePopup;

      if (typeof trigger === 'function') {
        const comp = trigger(isOpen);
        return !!trigger && React.cloneElement(comp, triggerProps);
      }

      return !!trigger && React.cloneElement(trigger, triggerProps);
    };

    const addWarperAction = () => ({
      className,
      sidebar,
      style: {
        ...contentStyle,
      },
      ref: contentRef,
      onClick: e => {
        e.stopPropagation();
      },
    });

    const renderHeader = () => {
      if (isEmpty(header)) return;
      return (
        <ModalHeader className="card-header">
          <div className="d-flex flex-column">
            {header.title && <ModalTitle>{header.title}</ModalTitle>}
            {header.subTitle && (
              <ModalSubTitle className="text-muted">{header.subTitle}</ModalSubTitle>
            )}
          </div>
          {closeButton && <CloseButton onClick={() => closePopup()} />}
        </ModalHeader>
      );
    };

    const renderContent = () => (
      <ModalDialog
        {...addWarperAction()}
        key="C"
        role="dialog"
        id={popupId.current}
        className={`modal-dialog modal-dialog-${sidebar ? 'vertical' : 'centered'}`}
        wide={wide}
      >
        <ModalContent sidebar={sidebar} className="modal-content">
          {renderHeader()}
          <div className="card-body" id="modal-content" style={{ overflowY: 'scroll' }}>
            {children && typeof children === 'function' ? children(closePopup, isOpen) : children}
          </div>
          {footer && footer(closePopup)}
        </ModalContent>
      </ModalDialog>
    );

    const content = [
      <Overlay
        key="O"
        data-testid="overlay"
        data-popup="modal"
        onClick={closeOnDocumentClick ? closePopup : undefined}
        tabIndex={-1}
      >
        <PopupContext.Provider value={{ closePopup }}>{renderContent()}</PopupContext.Provider>
      </Overlay>,
    ];

    return (
      <>
        {renderTrigger()}
        {isOpen && ReactDOM.createPortal(content, getRootPopup())}
      </>
    );
  },
);

Popup.ClosableBody = function ClosableBody({ children }) {
  const { closePopup } = React.useContext(PopupContext);

  return children({ closePopup });
};

Popup.Footer = function Footer({ children, width, ...rest }) {
  const { closePopup } = React.useContext(PopupContext);
  return (
    <div
      className="card-footer"
      style={{
        marginLeft: ' -1.5rem',
        marginRight: ' -1.5rem',
        marginBottom: '-1.5rem',
        width,
      }}
      {...rest}
    >
      {children({ closePopup })}
    </div>
  );
};

Popup.propTypes = {
  trigger: PropTypes.any,
  workInProgress: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  defaultOpen: PropTypes.bool,
  open: PropTypes.oneOf([PropTypes.undefined, PropTypes.bool]),
  disabled: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  contentStyle: PropTypes.object,
  className: PropTypes.string,
  lockScroll: PropTypes.bool,
  closeButton: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }),
  sidebar: PropTypes.bool,
  wide: PropTypes.bool,
  children: PropTypes.any,
  footer: PropTypes.func,
};

Popup.Footer.propTypes = {
  ...withDefaultProps(),
};

export default Popup;
