import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

import history from 'utils/history';
import { toastr } from 'utils';
import AuthServiceRambla from 'api/services/AuthServiceRambla';
import { ADMIN_LOGIN } from 'constants/routes';
import { CardContainer, Form, Wrapper } from 'components';

const ForgotPassword = () => {
  const [codeSent, setCodeSent] = React.useState(false);
  const { t } = useTranslation(['pages', 'app']);

  const onSubmitFunc = async (email, reset_password_code, password) => {
    try {
      if (!codeSent) {
        try {
          await AuthServiceRambla.sendChangePasswordCode(email);
          setCodeSent(true);
        } catch (e) {
          toastr.error(i18n.t('toastr:error'), t('forgotPassword.changePasswordEmailFailure'));
        }
        return;
      }

      await AuthServiceRambla.changePassword(reset_password_code, password);
      toastr.success(t('forgotPassword.changePasswordSuccess'));
      history.push(ADMIN_LOGIN);
    } catch (e) {
      if (e.response.data) {
        toastr.error(i18n.t('toastr:error'), Object.values(e.response.data).toString());
      }
    }
  };

  return (
    <CardContainer xs="12" md="8" lg="6">
      <h1 className="display-4 text-center text-uppercase mb-3">{t('forgotPassword.title')}</h1>
      <p className="text-muted text-center mb-5">{t('forgotPassword.subtitle')}</p>
      <Form
        initialValues={{
          email: '',
          reset_password_code: '',
          password: '',
        }}
        onSubmit={values => onSubmitFunc(values.email, values.reset_password_code, values.password)}
      >
        <Form.Group>
          <Form.Label>{t('app:email')}</Form.Label>
          <Form.Input name="email" type="text" />
        </Form.Group>
        {codeSent && (
          <>
            <Form.Group>
              <Form.Label>{t('forgotPassword.codeLabel')}</Form.Label>
              <Form.Input name="reset_password_code" type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('forgotPassword.passwordLabel')}</Form.Label>
              <Form.Input name="password" type="text" />
            </Form.Group>
          </>
        )}
        <Wrapper mb-3>
          <Form.Submit size="lg" variant="primary" block>
            {codeSent ? t('forgotPassword.changePassword') : t('forgotPassword.sendCode')}
          </Form.Submit>
        </Wrapper>
        <Wrapper d-flex justify-content-center align-items-center mt-5>
          <Link to={ADMIN_LOGIN} className="h3 text-muted">
            {t('forgotPassword.backToLogin')}
          </Link>
        </Wrapper>
      </Form>
    </CardContainer>
  );
};

export default ForgotPassword;
