import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as route from 'constants/routes';
import DashboardPage from 'pages/Dashboard/dashboardPage';
import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { autoLogin } from 'redux/auth/actions';
import { userLoggingSelector, superAdminSelector, roleSelector } from 'redux/auth/selectors';
import OnboardingPage from 'pages/Onboarding/onboardingPage';
import OnboardingRegionPage from 'pages/Onboarding/onBoardingRegionPage';
import OnboardingCheckoutTypePage from 'pages/Onboarding/onboardingCheckoutTypePage';
import OnboardingProductCatalogPage from 'pages/Onboarding/onboardingProductCatalogPage';
import OnboardingSellerAccessPage from 'pages/Onboarding/onboardingSellerAccessPage';
import OnboardingCommissionTrackingPage from 'pages/Onboarding/onboardingCommissionTrackingPage';
import OnboardingLocationManagementPage from 'pages/Onboarding/onboardingLocationManagementPage';
import OnboardingBrandTrackingPage from 'pages/Onboarding/onboardingBrandTrackingPage';
import OnboardingBrandInformationPage from 'pages/Onboarding/onboardingBrandInformationPage';
import OnboardingThemeCustomizationPage from 'pages/Onboarding/onboardingThemeCustomizationPage';
import OnboardingProductAttributesGenerationPage from 'pages/Onboarding/onboardingProductAttributesGenerationPage';
import OnboardingFinalSplashPage from 'pages/Onboarding/onboardingFinalSplashPage';
import PageNotFound from 'pages/404/PageNotFound';
import LoginPage from 'pages/Login/LoginPage';
import RegisterPage from 'pages/Register/registerPage';
import RegisterApprovalPage from 'pages/Register/registerApprovalPage';
import ForgotPassword from 'pages/Login/ForgotPassword';
import QRCodePage from 'pages/QRCodePage/QRCodePage';
import {
  CreateProductPage,
  RetailersPage,
  RegionsPage,
  TiersPage,
  ProductGroupsPage,
  BrandsPage,
  EditProductPage,
  AdminsPage,
  EditThemePage,
  CreateBrandContentPage,
  EditBrandContentPage,
  StylistPage,
  MyLocationsPage,
  PreviewLocationPage,
  LandingPage,
  ManageWelcomeScreen,
  LearningCenter,
  RetailerDetailsPage,
  LearningCentersList,
  EditLookPage,
  AddProducts,
  FeaturedArtistPage,
  ProductCatalogPage,
  RetailerLooksPage,
  AdminLooksPage,
  FavouriteProductsPage,
  CustomStoresPage,
  ProductsPage,
  BrandManagerPage,
  AdminEditLookPage,
} from 'pages';
import Layout from 'components/Layout/layout';
import { LoginRoute } from 'components/common/LoginRoute';
import entities from 'constants/entities';
import UserProfile from 'containers/UserProfile/userProfile';
import { DEFAULT_ROLE, RETAILER } from 'constants/roles';
import OwnerGroupPage from 'pages/OwnerGroupPage/OwnerGroupPage';
import * as Widgets from 'pages/Widgets';
import RetailerLoginPage from 'pages/Login/RetailerLoginPage';
import { LoadingSpinner } from 'components';
import { isOwnerGroup } from '../../utils/featureFlags.ts';
import { ADMIN } from '../../constants/roles';

const App = () => {
  const dispatch = useDispatch();
  const isUserLogging = useSelector(userLoggingSelector);
  const isSuperAdmin = useSelector(superAdminSelector);
  const role = useSelector(roleSelector);

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  if (isUserLogging) return null;

  return (
    <Switch>
      <LoginRoute exact path={route.ADMIN_LOGIN} component={LoginPage} />
      <LoginRoute exact path={route.RETAILER_LOGIN} component={RetailerLoginPage} />
      <LoginRoute exact path={route.REGISTER} component={RegisterPage} />
      <Route exact path={route.REGISTER_APPROVAL} component={RegisterApprovalPage} />
      <Route exact path={route.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={route.QRCODE} component={QRCodePage} />
      <Route exact path={route.WIDGET_TRENDING_LOOKS} component={Widgets.TrendingLooksWidget} />
      <Route exact path={route.WIDGET_NEW_LOOKS} component={Widgets.NewLooksWidget} />
      <Route exact path={route.WIDGET_FEATURED_ARTISTS} component={Widgets.FeaturedArtistsWidget} />
      <Route exact path="/not_found" component={PageNotFound} />
      <ProtectedRoute
        exact
        path={route.ONBOARDING}
        component={OnboardingPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_BRAND_INFORMATION}
        component={OnboardingBrandInformationPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_THEME_CUSTOMIZATION}
        component={OnboardingThemeCustomizationPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_CHECKOUT_TYPE}
        component={OnboardingCheckoutTypePage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_PRODUCT_CATALOG}
        component={OnboardingProductCatalogPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_ATTRIBUTES_GENERATION}
        component={OnboardingProductAttributesGenerationPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_SELLER_ACCESS}
        component={OnboardingSellerAccessPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_COMMISSION_TRACKING}
        component={OnboardingCommissionTrackingPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_LOCATION_MANAGEMENT}
        component={OnboardingLocationManagementPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_BRAND_TRACKING}
        component={OnboardingBrandTrackingPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_FINAL_SPLASH}
        component={OnboardingFinalSplashPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoute
        exact
        path={route.ONBOARDING_REGION}
        component={OnboardingRegionPage}
        condition={[DEFAULT_ROLE, ADMIN].includes(role)}
      />
      <ProtectedRoutes isSuperAdmin={isSuperAdmin} />
    </Switch>
  );
};

const ProtectedRoutes = ({ location, isSuperAdmin }) => {
  const role = useSelector(roleSelector);

  return (
    <Layout>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <ProtectedRoute exact path={route.ROOT}>
            <Redirect to={route.DASHBOARD} />
          </ProtectedRoute>
          <ProtectedRoute exact path={route.DASHBOARD} component={DashboardPage} />
          <ProtectedRoute exact path={route.BRAND_INFORMATION} component={DashboardPage} />
          <ProtectedRoute exact path={route.THEME_CUSTOMIZATION} component={DashboardPage} />
          <ProtectedRoute exact path={route.CHECKOUT_CONFIG} component={DashboardPage} />
          <ProtectedRoute exact path={route.PROFILE} component={UserProfile} />
          <ProtectedRoute exact path={route.LANDING_PAGE} component={LandingPage} />
          <ProtectedRoute exact path={route.WIDGETS} component={Widgets.AdminPreview} />
          <ProtectedRoute exact path={route.PRODUCTS} component={ProductsPage} />
          <ProtectedRoute exact path={route.PARENT_PRODUCTS} component={ProductsPage} />
          <ProtectedRoute exact path={route.CHILD_PRODUCTS} component={ProductsPage} />
          <ProtectedRoute exact path={route.COLORS} component={ProductsPage} />
          <ProtectedRoute exact path={route.SIZES} component={ProductsPage} />
          <ProtectedRoute exact path={route.CATEGORIES} component={ProductsPage} />
          <ProtectedRoute exact path={route.INVENTORIES} component={ProductsPage} />
          <ProtectedRoute exact path={route.BRAND_MANAGER} component={BrandManagerPage} />
          <ProtectedRoute
            exact
            path={route.DEFAULT_LOOK_IMAGE}
            component={BrandManagerPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.HOW_TO_HUB}
            component={BrandManagerPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.HOW_TO_HUB_RETAILER}
            component={BrandManagerPage}
            condition={[DEFAULT_ROLE, RETAILER].includes(role)}
          />
          <ProtectedRoute
            path={route.WELCOME_SCREENS}
            component={BrandManagerPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.BRAND_CONTENT}
            component={BrandManagerPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.EDIT_PRODUCTS}
            component={EditProductPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.CREATE_PRODUCT}
            component={CreateProductPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.product_group}
            path={route.PRODUCTS_GROUPS}
            component={ProductGroupsPage}
            condition={[DEFAULT_ROLE].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.retailer}
            path={route.RETAILERS}
            component={RetailersPage}
          />
          <ProtectedRoute exact path={route.STORES} component={CustomStoresPage} />
          <ProtectedRoute
            entity={entities.retailer}
            path={route.RETAILER}
            component={RetailerDetailsPage}
          />
          <ProtectedRoute
            exact
            entity={entities.region}
            path={route.REGIONS}
            component={RegionsPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          {isOwnerGroup() && (
            <ProtectedRoute exact path={route.OWNER_GROUP_PAGE} component={OwnerGroupPage} />
          )}
          <ProtectedRoute exact entity={entities.tier} path={route.TIERS} component={TiersPage} />
          <ProtectedRoute
            exact
            entity={entities.brand}
            path={route.BRAND}
            component={BrandsPage}
            condition={isSuperAdmin || [DEFAULT_ROLE].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.CREATE_BRAND_CONTENT}
            component={CreateBrandContentPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.EDIT_BRAND_CONTENT}
            component={EditBrandContentPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.featured_artist}
            path={route.FEATURED_ARTIST}
            component={FeaturedArtistPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.EDIT_THEME}
            component={EditThemePage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.admin}
            path={route.ADMINS}
            component={AdminsPage}
            condition={isSuperAdmin || [DEFAULT_ROLE].includes(role)}
          />
          <ProtectedRoute
            exact
            entity={entities.salon}
            path={route.MY_LOCATIONS}
            component={MyLocationsPage}
          />
          <ProtectedRoute path={route.MANAGE_STYLISTS} component={StylistPage} />
          <ProtectedRoute
            entity={entities.salon}
            path={route.MY_LOCATIONS_PREVIEW}
            component={PreviewLocationPage}
          />
          <ProtectedRoute
            path={route.MANAGE_WELCOME_SCREEN}
            component={ManageWelcomeScreen}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            path={route.LEARNING_CENTER}
            component={LearningCenter}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            path={route.LEARNING_CENTERS}
            component={LearningCentersList}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute path={route.ADMIN_LOOKS} component={AdminLooksPage} />
          <ProtectedRoute path={route.RETAILER_LOOKS} component={RetailerLooksPage} />
          <ProtectedRoute exact path={route.ADMIN_LOOK_EDIT} component={AdminEditLookPage} />
          <ProtectedRoute exact path={route.LOOK_EDIT} component={EditLookPage} />
          <ProtectedRoute
            exact
            path={route.LOOK_ADD_PRODUCTS}
            component={AddProducts}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.PRODUCT_CATALOG}
            component={ProductCatalogPage}
            condition={[DEFAULT_ROLE, ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.FAVORITE_PRODUCTS}
            component={FavouriteProductsPage}
            condition={[DEFAULT_ROLE, RETAILER].includes(role)}
          />
          <Redirect
            to={{
              pathname: '/not_found',
              // eslint-disable-next-line
              state: { from: location },
            }}
          />
        </Switch>
      </Suspense>
    </Layout>
  );
};

ProtectedRoutes.propTypes = {
  location: PropTypes.object,
  isSuperAdmin: PropTypes.bool.isRequired,
};

export default App;
