export const GET_USER_LOOKS = 'GET_USER_LOOKS';
export const GET_USER_LOOKS_SUCCESS = 'GET_USER_LOOKS_SUCCESS';
export const GET_ADMIN_LOOKS = 'GET_ADMIN_LOOKS';
export const GET_ADMIN_LOOKS_SUCCESS = 'GET_ADMIN_LOOKS_SUCCESS';

export const GET_LOOK = 'GET_LOOK';
export const GET_LOOK_SUCCESS = 'GET_LOOK_SUCCESS';
export const GET_ADMIN_LOOK = 'GET_ADMIN_LOOK';
export const GET_ADMIN_LOOK_SUCCESS = 'GET_ADMIN_LOOK_SUCCESS';
export const GET_LOOK_TEMPLATES = 'GET_LOOK_TEMPLATES';
export const GET_LOOK_TEMPLATES_SUCCESS = 'GET_LOOK_TEMPLATES_SUCCESS';

export const CREATE_BRAND_LOOK = 'CREATE_BRAND_LOOK';
export const CREATE_LOOK_DRAFT = 'CREATE_LOOK_DRAFT';
export const CREATE_LOOK_FROM_TEMPLATE = 'CREATE_LOOK_FROM_TEMPLATE';
export const CREATE_LOOK_FROM_TEMPLATE_SUCCESS = 'CREATE_LOOK_FROM_TEMPLATE_SUCCESS';

export const DELETE_LOOK = 'DELETE_LOOK';
export const DELETE_LOOK_SUCCESS = 'DELETE_LOOK_SUCCESS';
export const DELETE_ADMIN_LOOK = 'DELETE_ADMIN_LOOK';
export const DELETE_ADMIN_LOOK_SUCCESS = 'DELETE_ADMIN_LOOK_SUCCESS';

export const UPDATE_LOOK = 'UPDATE_LOOK';
export const UPDATE_LOOK_SUCCESS = 'UPDATE_LOOK_SUCCESS';

export const UPDATE_ADMIN_LOOK = 'UPDATE_ADMIN_LOOK';
export const UPDATE_ADMIN_LOOK_SUCCESS = 'UPDATE_ADMIN_LOOK_SUCCESS';

export const GET_AVAILABLE_PRODUCTS = 'GET_AVAILABLE_PRODUCTS';
export const GET_AVAILABLE_PRODUCTS_SUCCESS = 'GET_AVAILABLE_PRODUCTS_SUCCESS';
export const LOOK_CREATE_NEW = 'LOOK_CREATE_NEW';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const DELETE_TEMPLATE_LOOK_SUCCESS = 'DELETE_TEMPLATE_LOOK_SUCCESS';
export const UPDATE_LOOK_COVER_PHOTO = 'UPDATE_LOOK_COVER_PHOTO';
export const UPDATE_LOOK_COVER_PHOTO_SUCCESS = 'UPDATE_LOOK_COVER_PHOTO_SUCCESS';
export const ADD_PRODUCTS_TO_LOOK = 'ADD_PRODUCTS_TO_LOOK';
export const ADD_PRODUCTS_TO_LOOK_SUCCESS = 'ADD_PRODUCTS_TO_LOOK_SUCCESS';
export const CREATE_LOOK_FROM_DRAFT = 'CREATE_LOOK_FROM_DRAFT';
export const UPDATE_LOOK_IS_PRIVATE = 'UPDATE_LOOK_IS_PRIVATE';
export const UPDATE_LOOK_IS_PRIVATE_SUCCESS = 'UPDATE_LOOK_IS_PRIVATE_SUCCESS';
export const UPDATE_LOOK_IS_EDITABLE = 'UPDATE_LOOK_IS_EDITABLE';
export const UPDATE_LOOK_IS_EDITABLE_SUCCESS = 'UPDATE_LOOK_IS_EDITABLE_SUCCESS';
export const REMOVE_PRODUCT_FROM_LOOK = 'REMOVE_PRODUCT_FROM_LOOK';
export const REMOVE_PRODUCT_FROM_LOOK_SUCCESS = 'REMOVE_PRODUCT_FROM_LOOK_SUCCESS';
export const GET_COVER_PHOTOS = 'GET_COVER_PHOTOS';
export const GET_COVER_PHOTOS_SUCCESS = 'GET_COVER_PHOTOS_SUCCESS';
export const ADD_COVER_PHOTO = 'ADD_COVER_PHOTO';
export const REMOVE_COVER_PHOTO = 'REMOVE_COVER_PHOTO';
export const REMOVE_COVER_PHOTO_SUCCESS = 'REMOVE_COVER_PHOTO_SUCCESS';
export const CLEAR_LOOK = 'CLEAR_LOOK';
export const CLEAR_ADMIN_LOOK = 'CLEAR_ADMIN_LOOK';
