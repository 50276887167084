import {
  RAMBLA_FETCH_FAVORITE_PRODUCTS,
  RAMBLA_FETCH_FAVORITE_PRODUCTS_SUCCESS,
  RAMBLA_DELETE_FAVORITE_PRODUCT,
  RAMBLA_DELETE_FAVORITE_PRODUCT_SUCCESS,
  RAMBLA_ADD_FAVORITE_PRODUCTS,
  RAMBLA_ADD_FAVORITE_PRODUCTS_SUCCESS,
  RAMBLA_FETCH_PARENT_PRODUCTS,
  RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS,
  RAMBLA_CREATE_PARENT_PRODUCT,
  RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_UPDATE_PARENT_PRODUCT,
  RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_DELETE_PARENT_PRODUCT,
  RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS,
  RAMBLA_FETCH_CHILD_PRODUCTS,
  RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS,
  RAMBLA_CREATE_CHILD_PRODUCT,
  RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_UPDATE_CHILD_PRODUCT,
  RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_DELETE_CHILD_PRODUCT,
  RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS,
  RAMBLA_FETCH_COLORS,
  RAMBLA_FETCH_COLORS_SUCCESS,
  RAMBLA_CREATE_COLOR,
  RAMBLA_CREATE_COLOR_SUCCESS,
  RAMBLA_UPDATE_COLOR,
  RAMBLA_UPDATE_COLOR_SUCCESS,
  RAMBLA_DELETE_COLOR,
  RAMBLA_DELETE_COLOR_SUCCESS,
  RAMBLA_GENERATE_COLORS,
  RAMBLA_GENERATE_COLORS_SUCCESS,
  RAMBLA_FETCH_SIZES,
  RAMBLA_FETCH_SIZES_SUCCESS,
  RAMBLA_CREATE_SIZE,
  RAMBLA_CREATE_SIZE_SUCCESS,
  RAMBLA_UPDATE_SIZE,
  RAMBLA_UPDATE_SIZE_SUCCESS,
  RAMBLA_DELETE_SIZE,
  RAMBLA_DELETE_SIZE_SUCCESS,
  RAMBLA_GENERATE_SIZES,
  RAMBLA_GENERATE_SIZES_SUCCESS,
  RAMBLA_FETCH_CATEGORIES,
  RAMBLA_FETCH_CATEGORIES_SUCCESS,
  RAMBLA_CREATE_CATEGORY,
  RAMBLA_CREATE_CATEGORY_SUCCESS,
  RAMBLA_UPDATE_CATEGORY,
  RAMBLA_UPDATE_CATEGORY_SUCCESS,
  RAMBLA_DELETE_CATEGORY,
  RAMBLA_DELETE_CATEGORY_SUCCESS,
  RAMBLA_GENERATE_CATEGORIES,
  RAMBLA_GENERATE_CATEGORIES_SUCCESS,
  RAMBLA_FETCH_INVENTORIES,
  RAMBLA_FETCH_INVENTORIES_SUCCESS,
  RAMBLA_CREATE_INVENTORY,
  RAMBLA_CREATE_INVENTORY_SUCCESS,
  RAMBLA_UPDATE_INVENTORY,
  RAMBLA_UPDATE_INVENTORY_SUCCESS,
  RAMBLA_DELETE_INVENTORY,
  RAMBLA_DELETE_INVENTORY_SUCCESS,
  RAMBLA_GENERATE_INVENTORIES,
  RAMBLA_GENERATE_INVENTORIES_SUCCESS,
} from './constants';

// parent products
export const fetchParentProducts = () => ({
  type: RAMBLA_FETCH_PARENT_PRODUCTS,
});

export const fetchParentProductsSuccess = parent_products => ({
  type: RAMBLA_FETCH_PARENT_PRODUCTS_SUCCESS,
  payload: parent_products,
});

export const createParentProduct = (name, description, details, brand_uid, category_uid) => ({
  type: RAMBLA_CREATE_PARENT_PRODUCT,
  payload: { name, description, details, brand_uid, category_uid },
});

export const createParentProductSuccess = parent_product => ({
  type: RAMBLA_CREATE_PARENT_PRODUCT_SUCCESS,
  payload: parent_product,
});

export const updateParentProduct = (uid, name, description, details, category_uid) => ({
  type: RAMBLA_UPDATE_PARENT_PRODUCT,
  payload: { uid, name, description, details, category_uid },
});

export const updateParentProductSuccess = parent_product => ({
  type: RAMBLA_UPDATE_PARENT_PRODUCT_SUCCESS,
  payload: parent_product,
});

export const deleteParentProduct = uid => ({
  type: RAMBLA_DELETE_PARENT_PRODUCT,
  payload: { uid },
});

export const deleteParentProductSuccess = uid => ({
  type: RAMBLA_DELETE_PARENT_PRODUCT_SUCCESS,
  payload: { uid },
});

// child products
export const fetchChildProducts = () => ({
  type: RAMBLA_FETCH_CHILD_PRODUCTS,
});

export const fetchChildProductsSuccess = child_products => ({
  type: RAMBLA_FETCH_CHILD_PRODUCTS_SUCCESS,
  payload: child_products,
});

export const createChildProduct = (
  product_code,
  picture_id,
  parent_product_uid,
  inventory_uid,
  color_uid,
  size_uid,
  prices,
) => ({
  type: RAMBLA_CREATE_CHILD_PRODUCT,
  payload: {
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  },
});

export const createChildProductSuccess = child_product => ({
  type: RAMBLA_CREATE_CHILD_PRODUCT_SUCCESS,
  payload: child_product,
});

export const updateChildProduct = (
  uid,
  product_code,
  picture_id,
  parent_product_uid,
  inventory_uid,
  color_uid,
  size_uid,
  prices,
) => ({
  type: RAMBLA_UPDATE_CHILD_PRODUCT,
  payload: {
    uid,
    product_code,
    picture_id,
    parent_product_uid,
    inventory_uid,
    color_uid,
    size_uid,
    prices,
  },
});

export const updateChildProductSuccess = child_product => ({
  type: RAMBLA_UPDATE_CHILD_PRODUCT_SUCCESS,
  payload: child_product,
});

export const deleteChildProduct = uid => ({
  type: RAMBLA_DELETE_CHILD_PRODUCT,
  payload: { uid },
});

export const deleteChildProductSuccess = uid => ({
  type: RAMBLA_DELETE_CHILD_PRODUCT_SUCCESS,
  payload: { uid },
});

// colors
export const fetchColors = () => ({
  type: RAMBLA_FETCH_COLORS,
});

export const fetchColorsSuccess = colors => ({
  type: RAMBLA_FETCH_COLORS_SUCCESS,
  payload: colors,
});

export const createColor = (name, hex_value, brand_uid) => ({
  type: RAMBLA_CREATE_COLOR,
  payload: { name, hex_value, brand_uid },
});

export const createColorSuccess = color => ({
  type: RAMBLA_CREATE_COLOR_SUCCESS,
  payload: color,
});

export const updateColor = (uid, name, hex_value) => ({
  type: RAMBLA_UPDATE_COLOR,
  payload: { uid, name, hex_value },
});

export const updateColorSuccess = color => ({
  type: RAMBLA_UPDATE_COLOR_SUCCESS,
  payload: color,
});

export const deleteColor = uid => ({
  type: RAMBLA_DELETE_COLOR,
  payload: { uid },
});

export const deleteColorSuccess = uid => ({
  type: RAMBLA_DELETE_COLOR_SUCCESS,
  payload: { uid },
});

export const generateColors = () => ({
  type: RAMBLA_GENERATE_COLORS,
});

export const generateColorsSuccess = colors => ({
  type: RAMBLA_GENERATE_COLORS_SUCCESS,
  payload: colors,
});

// sizes
export const fetchSizes = () => ({
  type: RAMBLA_FETCH_SIZES,
});

export const fetchSizesSuccess = sizes => ({
  type: RAMBLA_FETCH_SIZES_SUCCESS,
  payload: sizes,
});

export const createSize = (name, brand_uid) => ({
  type: RAMBLA_CREATE_SIZE,
  payload: { name, brand_uid },
});

export const createSizeSuccess = size => ({
  type: RAMBLA_CREATE_SIZE_SUCCESS,
  payload: size,
});

export const updateSize = (uid, name) => ({
  type: RAMBLA_UPDATE_SIZE,
  payload: { uid, name },
});

export const updateSizeSuccess = size => ({
  type: RAMBLA_UPDATE_SIZE_SUCCESS,
  payload: size,
});

export const deleteSize = uid => ({
  type: RAMBLA_DELETE_SIZE,
  payload: { uid },
});

export const deleteSizeSuccess = uid => ({
  type: RAMBLA_DELETE_SIZE_SUCCESS,
  payload: { uid },
});

export const generateSizes = () => ({
  type: RAMBLA_GENERATE_SIZES,
});

export const generateSizesSuccess = sizes => ({
  type: RAMBLA_GENERATE_SIZES_SUCCESS,
  payload: sizes,
});

// categories
export const fetchCategories = () => ({
  type: RAMBLA_FETCH_CATEGORIES,
});

export const fetchCategoriesSuccess = categories => ({
  type: RAMBLA_FETCH_CATEGORIES_SUCCESS,
  payload: categories,
});

export const createCategory = (name, brand_uid) => ({
  type: RAMBLA_CREATE_CATEGORY,
  payload: { name, brand_uid },
});

export const createCategorySuccess = category => ({
  type: RAMBLA_CREATE_CATEGORY_SUCCESS,
  payload: category,
});

export const updateCategory = (uid, name) => ({
  type: RAMBLA_UPDATE_CATEGORY,
  payload: { uid, name },
});

export const updateCategorySuccess = category => ({
  type: RAMBLA_UPDATE_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteCategory = uid => ({
  type: RAMBLA_DELETE_CATEGORY,
  payload: { uid },
});

export const deleteCategorySuccess = uid => ({
  type: RAMBLA_DELETE_CATEGORY_SUCCESS,
  payload: { uid },
});

export const generateCategories = () => ({
  type: RAMBLA_GENERATE_CATEGORIES,
});

export const generateCategoriesSuccess = categories => ({
  type: RAMBLA_GENERATE_CATEGORIES_SUCCESS,
  payload: categories,
});

// inventories
export const fetchInventories = () => ({
  type: RAMBLA_FETCH_INVENTORIES,
});

export const fetchInventoriesSuccess = inventories => ({
  type: RAMBLA_FETCH_INVENTORIES_SUCCESS,
  payload: inventories,
});

export const createInventory = (name, brand_uid) => ({
  type: RAMBLA_CREATE_INVENTORY,
  payload: { name, brand_uid },
});

export const createInventorySuccess = inventory => ({
  type: RAMBLA_CREATE_INVENTORY_SUCCESS,
  payload: inventory,
});

export const updateInventory = (uid, name) => ({
  type: RAMBLA_UPDATE_INVENTORY,
  payload: { uid, name },
});

export const updateInventorySuccess = inventory => ({
  type: RAMBLA_UPDATE_INVENTORY_SUCCESS,
  payload: inventory,
});

export const deleteInventory = uid => ({
  type: RAMBLA_DELETE_INVENTORY,
  payload: { uid },
});

export const deleteInventorySuccess = uid => ({
  type: RAMBLA_DELETE_INVENTORY_SUCCESS,
  payload: { uid },
});

export const generateInventories = () => ({
  type: RAMBLA_GENERATE_INVENTORIES,
});

export const generateInventoriesSuccess = inventories => ({
  type: RAMBLA_GENERATE_INVENTORIES_SUCCESS,
  payload: inventories,
});

// favorite products
export const fetchFavoriteProducts = () => ({
  type: RAMBLA_FETCH_FAVORITE_PRODUCTS,
});

export const fetchFavoriteProductsSuccess = favorite_products => ({
  type: RAMBLA_FETCH_FAVORITE_PRODUCTS_SUCCESS,
  payload: favorite_products,
});

export const deleteFavoriteProduct = uid => ({
  type: RAMBLA_DELETE_FAVORITE_PRODUCT,
  payload: { uid },
});

export const deleteFavoriteProductSuccess = uid => ({
  type: RAMBLA_DELETE_FAVORITE_PRODUCT_SUCCESS,
  payload: { uid },
});

export const addFavoriteProducts = productsBatch => ({
  type: RAMBLA_ADD_FAVORITE_PRODUCTS,
  payload: productsBatch,
});

export const addFavoriteProductsSuccess = favorite_product => ({
  type: RAMBLA_ADD_FAVORITE_PRODUCTS_SUCCESS,
  payload: favorite_product,
});
