import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: ${props => (props.squareVersion ? props.size + 4 : props.size - 1.5)}px;
  height: ${props => (props.squareVersion ? props.size - 3.55 : props.size - 1.5)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => (props.round ? '50%' : '0')};
  background-color: ${props => props.fillColor};
  cursor: pointer;

  svg {
    width: ${props => props.size / 2}px;
    height: ${props => props.size / 2}px;
  }
`;

const SocialIcon = ({
  src,
  size = 50,
  round = false,
  alt = '',
  fillColor = 'transparent',
  onClick,
  style = {},
  squareVersion = false,
}) => (
  <IconWrapper
    size={size}
    round={round}
    fillColor={fillColor}
    onClick={onClick}
    style={style}
    squareVersion={squareVersion}
  >
    {src && (
      <img
        src={src}
        aria-label={alt}
        alt={alt || 'social-icon'}
        width={size / 2}
        heighta={size / 2}
      />
    )}
  </IconWrapper>
);

SocialIcon.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number,
  round: PropTypes.bool,
  fillColor: PropTypes.string,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
  squareVersion: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SocialIcon;
