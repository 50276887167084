import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Route, Redirect, Link } from 'react-router-dom';
import { ramblaUserSelector, userLoggingSelector, roleSelector } from 'redux/auth/selectors';
import { ADMIN_LOGIN, DASHBOARD } from 'constants/routes';

import * as Roles from 'constants/roles';

export const PermissionContext = React.createContext();

export function ProtectedRoute({ component: Component, condition, location, ...rest }) {
  const user = useSelector(ramblaUserSelector);
  const isUserLogging = useSelector(userLoggingSelector);
  const pageName = location.pathname.split('/').slice(-1)[0] || 'Dashboard';
  const pageTitle = capitalize(pageName).replaceAll('-', ' ');
  const role = useSelector(roleSelector);

  const isRetailer = role === Roles.RETAILER;
  const [userPermissions, setUserPermissions] = useState();
  const { t } = useTranslation(['app']);

  useEffect(() => {
    if (user) {
      if (isRetailer) {
        setUserPermissions({
          read: true,
          create: false,
          update: false,
          delete: false,
        });
      } else {
        setUserPermissions({
          read: true,
          create: true,
          update: true,
          delete: true,
        });
      }
    }
  }, [user, isRetailer]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isUserLogging) {
          return null;
        }

        if (!user) {
          return (
            <div
              style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'pre-line',
                textAlign: 'center',
              }}
            >
              <h1>{t('app:noAccess')}</h1>
              <Link to={ADMIN_LOGIN}>{t('app:login').toUpperCase()}</Link>
            </div>
          );
        }

        if (typeof condition !== 'undefined' && !condition) {
          return <Redirect to={DASHBOARD} />;
        }

        if (user?.email) {
          return (
            <PermissionContext.Provider
              value={{
                permission: userPermissions,
              }}
            >
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
              <Component {...props} />
            </PermissionContext.Provider>
          );
        }

        return (
          <Redirect
            to={{
              pathname: ADMIN_LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  condition: PropTypes.bool,
  rest: PropTypes.any,
};
