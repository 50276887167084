export const ROOT = '/';
export const DASHBOARD = '/dashboard';
export const ONBOARDING = '/onboarding';
export const ONBOARDING_BRAND_INFORMATION = '/onboarding/brand-information';
export const ONBOARDING_THEME_CUSTOMIZATION = '/onboarding/theme';
export const ONBOARDING_REGION = '/onboarding/region';
export const ONBOARDING_CHECKOUT_TYPE = '/onboarding/checkout-type';
export const ONBOARDING_PRODUCT_CATALOG = '/onboarding/product-catalog';
export const ONBOARDING_ATTRIBUTES_GENERATION = '/onboarding/generate-attributes';
export const ONBOARDING_SELLER_ACCESS = '/onboarding/seller-access';
export const ONBOARDING_COMMISSION_TRACKING = '/onboarding/commission-tracking';
export const ONBOARDING_LOCATION_MANAGEMENT = '/onboarding/location-management';
export const ONBOARDING_BRAND_TRACKING = '/onboarding/brand-tracking';
export const ONBOARDING_FINAL_SPLASH = '/onboarding/finalizing';
export const BRAND_INFORMATION = '/brand-information/edit';
export const THEME_CUSTOMIZATION = '/theme/edit';
export const PRODUCTS = '/products';
export const PARENT_PRODUCTS = '/parent_products';
export const CHILD_PRODUCTS = '/child_products';
export const COLORS = '/colors';
export const SIZES = '/sizes';
export const CATEGORIES = '/categories';
export const INVENTORIES = '/inventories';
export const EDIT_PRODUCTS = '/product/:id';
export const CREATE_PRODUCT = '/products/create';
export const PRODUCTS_GROUPS = '/products-groups';
export const RETAILERS = '/retailers';
export const STORES = '/stores';
export const REGIONS = '/regions';
export const TIERS = '/tiers';
export const BRAND = '/brands';
export const BRAND_CONTENT = '/brand_content';
export const CREATE_BRAND_CONTENT = '/brand_content/create';
export const EDIT_BRAND_CONTENT = '/brand_content/:id';
export const FEATURED_ARTIST = '/featured-artist';
export const ADMIN_LOGIN = '/login';
export const RETAILER_LOGIN = '/login-retailer';
export const REGISTER = '/register';
export const REGISTER_APPROVAL = '/register-approval/:uid';
export const EDIT_THEME = '/cover-photo';
export const ADMINS = '/admins';
export const ADD_NUMBER = '/add-number';
export const MY_LOCATIONS = '/location';
export const MY_LOCATIONS_PREVIEW = '/location/:id';
export const MY_LOCATIONS_EDIT = '/location/:id/page-edit';
export const MANAGE_STYLISTS = '/stylists';
export const APPROVE_STYLISTS = '/stylists/approve';
export const CREATE_PAGE_TEMPLATE = '/page-builder/create';
export const WELCOME_SCREENS = '/welcome_screen';
export const MANAGE_WELCOME_SCREEN = '/welcome_screen/:id';
export const LANDING_PAGE = '/landing-page';
export const LEARNING_CENTERS = '/learning_center';
export const LEARNING_CENTER = '/learning_center/:id';
export const RETAILER = '/retailer/:id';
export const FORGOT_PASSWORD = '/forgot-password';
export const PROFILE = '/profile';
export const QRCODE = '/qrcode';
export const ADMIN_LOOKS = '/admin-looks';
export const RETAILER_LOOKS = '/retailer-looks';
export const ADMIN_LOOK_EDIT = '/admin-look/:id';
export const LOOK_EDIT = '/look/:id';
export const LOOK_ADD_PRODUCTS = '/look/:id/add-products';
export const OWNER_GROUP_PAGE = '/owner-group-page';
export const PRODUCT_CATALOG = '/product-catalog';
export const WIDGET_TRENDING_LOOKS = '/widgets/trending-looks';
export const WIDGET_TEST = '/widgets/test';
export const WIDGET_NEW_LOOKS = '/widgets/new-looks';
export const WIDGET_FEATURED_ARTISTS = '/widgets/featured-artists';
export const WIDGETS = '/widgets';
export const FAVORITE_PRODUCTS = '/favorite-products';
export const DEFAULT_LOOK_IMAGE = '/default-look-image';
export const HOW_TO_HUB = '/how-to-hub';
export const HOW_TO_HUB_RETAILER = '/how-to-hub-retailer';
export const CHECKOUT_CONFIG = '/checkout-config/edit';
export const BRAND_MANAGER = '/brand-manager';
