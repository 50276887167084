import { groupFavoriteProductsByCategory } from 'utils/products';

export const ramblaParentProductsSelector = state => state.products.parent_products;
export const ramblaChildProductsSelector = state => state.products.child_products;
export const ramblaProductColorsSelector = state => state.products.colors;
export const ramblaProductSizesSelector = state => state.products.sizes;
export const ramblaProductCategoriesSelector = state => state.products.categories;
export const ramblaProductInventoriesSelector = state => state.products.inventories;
export const ramblaFavoriteProductsSelector = state => state.products.favorite_products;
export const ramblaFavoriteProductsByCategorySelector = state =>
  groupFavoriteProductsByCategory(state.products.parent_products.data);
