import * as Roles from 'constants/roles';

export const userRole = user => {
  if (user?.admin_details) {
    return Roles.ADMIN;
  }

  if (user?.retailer_accounts?.length) return Roles.RETAILER;

  return Roles.DEFAULT_ROLE;
};
