import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Card, Page } from 'components';
import { ramblaUserSelector, roleSelector } from 'redux/auth/selectors';
import * as Roles from 'constants/roles';

const UserProfile = () => {
  const { t } = useTranslation(['app']);
  const user = useSelector(ramblaUserSelector);
  const role = useSelector(roleSelector);

  const isRetailer = role === Roles.RETAILER;

  const extractRegion = () =>
    isRetailer
      ? user?.retailer_accounts[0]?.region?.name
      : user?.admin_details?.brand?.regions.map(r => r.name).join(', ');

  const extractBrand = () =>
    isRetailer ? user?.retailer_accounts[0]?.brand?.name : user?.admin_details?.brand?.name;

  const getData = () => {
    const data = [
      {
        label: t('app:name'),
        value: user.name,
      },
      {
        label: t('app:last_name'),
        value: user.last_name,
      },
      {
        label: t('app:email'),
        value: user.email,
      },
      {
        label: t('app:entities.region'),
        value: extractRegion(),
      },
      {
        label: t('app:entities.brand'),
        value: extractBrand(),
      },
      ...(isRetailer
        ? [
            {
              label: t('app:retailerStore'),
              value: user?.retailer_accounts[0]?.url,
              isLink: true,
            },
          ]
        : []),
    ];

    return data;
  };

  return (
    <Page title={t('profileTitle')} description={t('profileDescription')} withBackButton={false}>
      <Container className="px-5 mt-5 mx-auto">
        <Card title={t('app:profileTitle')} subtitle={t('app:profileSubtitle')}>
          <ul className="list-group">
            {getData().map(item => (
              <li className="list-group-item">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0 w-50">{item.label}</h4>
                  {item?.isLink ? (
                    <Button
                      variant="link"
                      href={`https://${item.value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`m-0 text-right ${item.value?.length < 50 ? 'w-50' : 'w-75'}`}
                    >
                      {item.value}
                    </Button>
                  ) : (
                    <p className={`m-0 text-right ${item.value?.length < 50 ? 'w-50' : 'w-75'}`}>
                      {item.value}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Card>
      </Container>
    </Page>
  );
};

export default UserProfile;
