import { sortBy, uniqBy, groupBy, partition } from 'lodash';
import i18n from '../i18n';

export const formatPrice = (price, currency, onlyDigitValues = false, textForZeroValue = '') => {
  const priceAsNumber = Number(price);
  const formattedPrice = Number.isNaN(priceAsNumber)
    ? '-'
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }).format(priceAsNumber);

  if ((price === '0.00' || price === 0) && !onlyDigitValues) {
    return textForZeroValue || 'Free';
  }
  return formattedPrice === '-' ? '-' : formattedPrice;
};

export const productPrice = (skus, currency = 'USD') => {
  if (skus.length <= 1) {
    return formatPrice(skus[0]?.retail_price || 0, currency);
  }
  const sortedSkus = sortBy(skus, s => Number(s.retail_price));
  const minPrice = sortedSkus[0].retail_price;
  const maxPrice = sortedSkus[sortedSkus.length - 1].retail_price;
  if (minPrice === maxPrice) {
    return formatPrice(minPrice || 0, currency);
  }
  return `${formatPrice(minPrice, currency)} - ${formatPrice(maxPrice, currency)}`;
};

export const getProductImage = skus =>
  skus.find(s => s.is_primary)?.images[0] || skus[0]?.images[0];

export const VariantType = {
  COLOR: 'COLOR',
  SIZE: 'SIZE',
};

export const getVariants = (skus = []) => {
  const colors = skus
    .map(s => ({ id: s.id, variantName: s?.color?.name, hexValue: s?.color?.hex_value }))
    .filter(s => s.variantName);

  const uniqColors = uniqBy(colors, sku => sku.variantName);

  const sizes = skus
    .map(s => ({ id: s.id, variantName: s?.size?.name }))
    .filter(s => s.variantName);
  const uniqSizes = uniqBy(sizes, sku => sku.variantName);

  const moreSizes = uniqSizes.length > uniqColors.length;

  return {
    variantType: moreSizes ? VariantType.SIZE : VariantType.COLOR,
    variants: moreSizes ? uniqSizes : uniqColors,
  };
};

const ACTIVE = 'Active';
const OUT_OF_STOCK = 'Temporarily out of stock';
const SOLD_OUT = 'Sold out';
const COMING_SOON = 'Coming soon';

export const getInventoryLabel = inventory => {
  switch (inventory) {
    case OUT_OF_STOCK:
      return i18n.t('ownerPortal:Looks.outOfStock');
    case COMING_SOON:
      return i18n.t('ownerPortal:Looks.comingSoon');
    case SOLD_OUT:
      return i18n.t('ownerPortal:Looks.soldOut');
    default:
      return '';
  }
};

export const getProductInventoryLabel = product => {
  const { primary_sku, sub_skus } = product;
  let inventory = ACTIVE;
  const isAddedProduct = !!primary_sku;

  if (isAddedProduct) {
    inventory = primary_sku?.inventory;
  } else {
    // If at least 1 sub_sku is active, label product as active
    const activeSku = sub_skus?.find(sku => sku?.inventory === ACTIVE);
    if (activeSku) {
      inventory = ACTIVE;
    } else {
      // if no active skus, set label from primary or first sku
      const primarySku = sub_skus?.find(sku => sku?.is_primary) || sub_skus?.[0];
      inventory = primarySku?.inventory;
    }
  }

  return getInventoryLabel(inventory);
};

export const splitIngredients = ingredients =>
  ingredients
    ?.replace('Ingredients: ', '')
    .split(',')
    .map(i => i.trim());

export const groupProductsByCategory = products => {
  const mappedProducts = products.map(p => ({
    ...p,
    category: p.category || 'others',
  }));

  return { ...groupBy(mappedProducts, p => p.category), all: mappedProducts };
};

export const groupFavoriteProductsByCategory = products => {
  const mappedProducts = products.map(product => ({
    ...product,
    category: product.category.name || 'others',
  }));

  const groupedProducts = groupBy(mappedProducts, product => product.category);

  return { ...groupedProducts, all: mappedProducts };
};

const TOP_CATEGORIES_MAC = ['lippenstift', 'foundation', 'mascara', 'primer', 'shadow'];

const TOP_CATEGORIES_AVEDA = [
  'shampoo',
  'conditioner',
  'style-prep&trade;',
  'hair care sets',
  'moisturizer',
];

const BOTTOM_CATEGORIES_MAC = [];
const BOTTOM_CATEGORIES_AVEDA = ['tea'];

export const orderCategories = (categories, isMac) => {
  const topCats = isMac ? TOP_CATEGORIES_MAC : TOP_CATEGORIES_AVEDA;
  const bottomCats = isMac ? BOTTOM_CATEGORIES_MAC : BOTTOM_CATEGORIES_AVEDA;

  const [top, rest] = partition(categories, c => topCats.includes(c));
  const [middle, bottom] = partition(rest, c => !bottomCats.includes(c));
  return [...topCats.filter(c => top.includes(c)), ...middle, ...bottom];
};
