import { childProduct } from 'api/models/Product';

const updateUrlPreview = ({
  destination,
  quantity,
  locationId,
  publishId,
  trackingBrandId,
  parameters,
  redirectUrl,
  customParameters,
}) => {
  if (!destination) {
    return '';
  }

  const CUSTOM_VALUE = 'v';
  const queryStringComponents = [];
  const productQueryString = [];

  if (locationId) queryStringComponents.push(`${locationId}=${CUSTOM_VALUE}`);

  if (publishId) queryStringComponents.push(`${publishId}=${CUSTOM_VALUE}`);

  if (trackingBrandId) queryStringComponents.push(`${trackingBrandId}=${CUSTOM_VALUE}`);

  parameters
    .filter(param => param.key && param.productField)
    .forEach(param => {
      productQueryString.push(`${param.key}=${childProduct[param.productField] || CUSTOM_VALUE}`);
    });

  customParameters
    .filter(param => param.key && param.value)
    .forEach(param => {
      queryStringComponents.push(`${param.key}=${param.value}`);
    });

  if (quantity) productQueryString.push(`${quantity.replace('&', '')}`);

  const queryString = queryStringComponents.join('&');
  const productQuery = productQueryString.join('&');

  const encodedRedirectUrl = encodeURIComponent(redirectUrl);

  return `${destination}${productQuery ? `/product?${productQuery}` : ''}${
    queryString ? `&${queryString}` : ''
  }${redirectUrl ? `&redirect=${encodedRedirectUrl}` : ''}`;
};

export default updateUrlPreview;
