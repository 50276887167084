import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'redux/auth/actions';
import { toastr } from 'utils';
import { roleSelector } from 'redux/auth/selectors';

import { Navigation } from 'components';
import { PROFILE } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import * as userRoles from 'constants/roles';
import { menuItems } from './menu';

const SideNavigation = ({ compact, toggleNavigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector(roleSelector);
  const isOwnerPortalUser = [userRoles.ADMIN].includes(role);
  const translationPrefix = isOwnerPortalUser ? 'ownerPortal:menu.' : 'menu:';

  const buildMenu = (items, subItem = false) =>
    items.map(item => {
      if (item && item?.items && (!item.roles || item?.roles?.includes(role))) {
        return (
          <Navigation.Link
            key={`navlink-${item.id}`}
            id={`navlink-${item.id}`}
            to={item.link}
            name={
              [userRoles.DEFAULT_ROLE].includes(role)
                ? item.adminToolLabel || item.label
                : item.label
            }
            icon={item.icon}
            renderIcon={item.renderIcon}
            subItem={subItem}
            guard={item?.guard}
          >
            {buildMenu(item.items, true)}
          </Navigation.Link>
        );
      }
      if (item && (!item.roles || item?.roles?.includes(role))) {
        return (
          <Navigation.Link
            key={`navlink-${item.id}`}
            to={item.link}
            name={
              [userRoles.DEFAULT_ROLE].includes(role)
                ? item.adminToolLabel || item.label
                : item.label
            }
            icon={item.icon}
            renderIcon={item.renderIcon}
            subItem={subItem}
          />
        );
      }
      return null;
    });

  return (
    <Navigation toggleNavigation={toggleNavigation} compact={compact}>
      <div className="d-flex flex-column justify-content-between" style={{ flex: 1 }}>
        <div>{buildMenu(menuItems)}</div>
        <div>
          <Navigation.Link
            active={false}
            icon="fe-user"
            name={t(`${translationPrefix}profile`)}
            to={PROFILE}
          />
          <Navigation.Link
            name={t(`${translationPrefix}logout`)}
            icon="fe-log-out"
            active={false}
            to="#"
            onClick={() =>
              toastr.confirm(t('toastr:logoutQuestion'), {
                onOk: () => {
                  dispatch(logoutUser([userRoles.DEFAULT_ROLE].includes(role)));
                },
                dispatch,
              })
            }
          />
        </div>
      </div>
    </Navigation>
  );
};

SideNavigation.propTypes = {
  compact: PropTypes.bool,
  toggleNavigation: PropTypes.func,
};

export default SideNavigation;
