import { lazy } from 'react';

const ProductPage = lazy(() => import('./Product/productPage'));
const CreateProductPage = lazy(() => import('./Product/createProductPage'));
const EditProductPage = lazy(() => import('./Product/editProductPage'));
const ProductGroupsPage = lazy(() => import('./ProductGroups/productGroupsPage'));
const RegionsPage = lazy(() => import('./Regions/regionsPage'));
const TiersPage = lazy(() => import('./Tiers/tiersPage'));
const RetailersPage = lazy(() => import('./Retailers/retailersPage'));
const StoresPage = lazy(() => import('./Stores/storesPage'));
const CustomStoresPage = lazy(() => import('./Stores/components/CustomStore'));
const ParentProductsPage = lazy(() => import('./Products/parentProductsPage'));
const ChildProductsPage = lazy(() => import('./Products/childProductsPage'));
const ColorsPage = lazy(() => import('./Colors/colorsPage'));
const SizesPage = lazy(() => import('./Sizes/sizesPage'));
const CategoriesPage = lazy(() => import('./Categories/categoriesPage'));
const InventoriesPage = lazy(() => import('./Inventories/inventoriesPage'));
const RetailerDetailsPage = lazy(() => import('./Retailers/retailerDetails/retailerDetailsPage'));

const BrandsPage = lazy(() => import('./Library/brandsPage'));
const BrandContentPage = lazy(() => import('./BrandContent/brandContentPage'));
const CreateBrandContentPage = lazy(() => import('./BrandContent/createBrandContentPage'));
const EditBrandContentPage = lazy(() => import('./BrandContent/editBrandContentPage'));
const EditThemePage = lazy(() => import('./Themes/editThemePage'));

const AdminsPage = lazy(() => import('./Admins/adminsPage'));
const ManageWelcomeScreen = lazy(() => import('./ManageWelcomeScreen/manageWelcomeScreen'));
const WelcomeScreenPage = lazy(() => import('./ManageWelcomeScreen/WelcomeScreenPage'));

const StylistPage = lazy(() => import('./Stylists/StylistPage'));
const ApproveStylistPage = lazy(() => import('./Stylists/ApproveStylistPage'));
const MyLocationsPage = lazy(() => import('./Locations/MyLocationsPage'));
const PreviewLocationPage = lazy(() => import('./Locations/PreviewLocationPage'));
const LandingPage = lazy(() => import('./LandingPage/landingPage'));
const LearningCenter = lazy(() => import('./LearningCenter/LearningCenter'));
const LearningCentersList = lazy(() => import('./LearningCenter/LearningCentersList'));
const RetailerLooksPage = lazy(() => import('./Looks/pages/RetailerLooksPage'));
const AdminLooksPage = lazy(() => import('./Looks/pages/AdminLooksPage'));
const AdminEditLookPage = lazy(() => import('./Looks/pages/EditLookPage/AdminEditLookPage'));
const EditLookPage = lazy(() => import('./Looks/pages/EditLookPage/EditLookPage'));
const AddProducts = lazy(() => import('./Looks/pages/AddProductsPage/AddProductsPage'));

const ForgetPassword = lazy(() => import('./Login/ForgotPassword'));
const QRCodePage = lazy(() => import('./QRCodePage/QRCodePage'));
const FeaturedArtistPage = lazy(() => import('./FeaturedArtist/FeaturedArtistPage'));

const ProductCatalogPage = lazy(() => import('./ProductCatalog'));
const FavouriteProductsPage = lazy(() => import('./FavouriteProducts'));

const ProductsPage = lazy(() => import('./Products'));
const BrandManagerPage = lazy(() => import('./BrandManager'));
const DefaultLookImagePage = lazy(() => import('./DefaultLookImage/DefaultLookImagePage'));
const HowToHubPage = lazy(() => import('./HowToHub/HowToHubPage'));
const HowToHubRetailerPage = lazy(() => import('./HowToHub/HowToHubRetailerPage'));

export {
  ProductPage,
  CreateProductPage,
  EditProductPage,
  RetailersPage,
  StoresPage,
  ParentProductsPage,
  ChildProductsPage,
  ColorsPage,
  SizesPage,
  CategoriesPage,
  InventoriesPage,
  RetailerDetailsPage,
  RegionsPage,
  TiersPage,
  ProductGroupsPage,
  BrandsPage,
  BrandContentPage,
  CreateBrandContentPage,
  EditBrandContentPage,
  EditThemePage,
  AdminsPage,
  StylistPage,
  ApproveStylistPage,
  MyLocationsPage,
  PreviewLocationPage,
  ManageWelcomeScreen,
  LandingPage,
  LearningCenter,
  ForgetPassword,
  QRCodePage,
  LearningCentersList,
  WelcomeScreenPage,
  RetailerLooksPage,
  AdminLooksPage,
  AdminEditLookPage,
  EditLookPage,
  AddProducts,
  FeaturedArtistPage,
  ProductCatalogPage,
  FavouriteProductsPage,
  CustomStoresPage,
  ProductsPage,
  BrandManagerPage,
  DefaultLookImagePage,
  HowToHubPage,
  HowToHubRetailerPage,
};
